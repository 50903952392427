import { Layout } from "antd";
import { useState, useEffect, createContext } from "react";
import csv from "csvtojson";
import { downloadData } from "aws-amplify/storage";
import _ from "lodash";
import { IProjectModel } from "../../stores/project/projectModel";
import { IuploadDataModel } from "../../stores/uploadData";
import { jsonSchemaGetExamples } from "../../helpers/jsonSchema.helpers";
import { useMst } from "../../stores/Root";
import "./OverviewPage.scss";
import { observer } from "mobx-react-lite";
import { Content } from "antd/es/layout/layout";
import { BlingsCardDescription } from "../../components/BlingsCard_Description/BlingsCardDescription";
import BlingsCardDynamicData from "../../components/BlingsCard_DynamicData/BlingsCardDynamicData";
import BlingsDynamicDataModal from "../../components/BlingsModal_DynamicData/BlingsDynamicDataModal";
import { useBlingsDynamicForm } from "../../helpers/BlingsDynamicForm";
import { usePlayerContext } from "../../contexts/playerContext";
import { PlayerComponent } from "./PlayerComponent";

type Props = {
  project: IProjectModel;
  uploadData: IuploadDataModel;
  isLoading: boolean;
};

export const OverviewPageContext = createContext<{
  project: IProjectModel;
  dataPointsFromCSV: { [id: string]: Object } | undefined;
  dataPointsFromManual: { [id: string]: Object } | undefined;
}>({
  project: {} as IProjectModel,
  dataPointsFromCSV: undefined,
  dataPointsFromManual: undefined,
});

const OverviewPage = observer(({ project, isLoading }: Props) => {
  // New code
  // Store and context properties
  const { saveProject, refreshProject } = useMst((state) => {
    return {
      saveProject: state.projectsStore.saveProject,
      refreshProject: state.projectsStore.refreshProject,
    };
  });

  // Extra logic
  /**
   * Data examples that match the ones from the schema structure
   */
  const dataExamples = jsonSchemaGetExamples(
    JSON.parse(project.stateJsonSchemaStr || "{}")
  );
  const schema = JSON.parse(project.stateJsonSchemaStr || "{}");

  // State definition
  const [dataPointsFromCSV, setDataPointsFromCSV] = useState<
    { [id: string]: Object } | undefined
  >(undefined);
  const [dataPointsFromManual, setDataPointsFromManual] = useState<
    { [id: string]: Object } | undefined
  >(undefined);
  const [isModalOpen, setIsModalOpen] = useState<{
    modalOpen: boolean;
    mode: "create" | "edit";
  }>({ modalOpen: false, mode: "create" });
  const {
    selectedDataPointId,
    setSelectedDataPointId,
    currentFormData,
    setLastFormChange,
    setCurrentFormData,
    setFormHasChanged,
    recordChanged,
    setChangedFromRecordData,
    debouncedValue,
    submit,
    setCurrentDataPointsFromCSV,
    setCurrentDataPointsFromManual,
    fillDataFromRecord,
  } = useBlingsDynamicForm(
    project,
    dataExamples,
    schema,
    dataPointsFromManual,
    dataPointsFromCSV
  );

  // Function definition
  /**
   * Fetch the data points from the csv file
   * @param fileName The project attached csv file name
   */
  async function fetchSourceRecord(fileName: string) {
    const res = await downloadData(
      {
        key: fileName,
      }
      //fileName, { download: true })
    ).result;
    const text = await res.body.text();
    csv()
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      .fromString(text)
      .then((lines) => {
        const srs = {} as any;
        lines.forEach((line) => {
          const { id, ...rest } = line;
          srs[id] = rest;
        });
        setDataPointsFromCSV(srs);
      });
  }

  /**
   * Fetch the data points from the manual data points
   * @param currentId The current project id
   */
  async function fetchDpRecords(currentId: string) {
    try {
      await project.loadExistingDPs();
      if (project.id === currentId && project.createdDPList.length) {
        const srs = {} as any;
        project.createdDPList.forEach((dp) => {
          srs[dp.dataId] = dp.record;
        });
        setDataPointsFromManual(srs);
      }
    } catch (error) {
      console.error(error);
    }
  }

  /**
   * Get the data points from the csv file and the manual data points
   * @param fileName The project attached csv file name
   */
  async function getData(fileName: string | null) {
    if (fileName) {
      await fetchSourceRecord(fileName);
    }
    const currentId = project.id;
    await fetchDpRecords(currentId);
  }

  async function saveProjectDescription(newDescription: string) {
    await saveProject({
      id: project.id,
      description: newDescription,
    });
    await refreshProject(project.id);
  }

  // UseEffect definition
  // Fetch all datapoints for the current project
  useEffect(() => {
    getData(project.fileName);
  }, [project, project.fileName, project.createNewDPStatus]);

  useEffect(() => {
    setCurrentDataPointsFromCSV(dataPointsFromCSV);
    setCurrentDataPointsFromManual(dataPointsFromManual);
    if (selectedDataPointId) {
      setCurrentDataPointsFromCSV(dataPointsFromCSV);
      setCurrentDataPointsFromManual(dataPointsFromManual);
      fillDataFromRecord(selectedDataPointId);
    }
  }, [dataPointsFromCSV, dataPointsFromManual]);

  // When the last change is debounced, submit the form
  useEffect(() => {
    submit.current();
  }, [debouncedValue]);

  // Extra logic
  // const schema = JSON.parse(project.stateJsonSchemaStr || "{}");
  const showDemoSdk =
    project.videoPartNames && project.videoPartNames.length !== 0; // Only show the SDK player if there are scenes to show

  // Context definition
  const overviewPageContext = {
    project,
    dataPointsFromCSV,
    dataPointsFromManual,
  };

  if (!showDemoSdk || isLoading) {
    return (
      <div className={"OverviewPage"}>
        <div className="text">
          Create a video using Blings extension,
          <br />
          and see a demo here
        </div>
      </div>
    );
  }

  const dataPointSelectorProps = {
    selectedDataPointId,
    setSelectedDataPointId,
    fillDataFromRecord,
  };

  const dynamicFormProps = {
    schema,
    currentFormData,
    setLastFormChange,
    setCurrentFormData,
    setFormHasChanged,
    setChangedFromRecordData,
    submit,
  };

  /**
   * NEW LAYOUT
   * TITLE
   * DATA | PLAYER
   */
  return (
    <OverviewPageContext.Provider value={overviewPageContext}>
      <Layout className="overview-page-layout">
        <Layout className="content-layout">
          <Content className="content">
            <div className="data">
              {
                // Only show the description card if there is a description
                project.description && (
                  <BlingsCardDescription
                    title="Description"
                    description={project.description || ""}
                    canEdit={true}
                    saveDescriptionChange={saveProjectDescription}
                  />
                )
              }
              <BlingsCardDynamicData
                setIsModalOpen={setIsModalOpen}
                dynamicFormProps={dynamicFormProps}
                dataPointSelectorProps={dataPointSelectorProps}
                readonly={true}
                getData={getData}
              />
            </div>
            <div className="demo-sdk">
              {showDemoSdk && !isModalOpen.modalOpen && (
                <PlayerComponent
                  commandBarOptions={{
                    showCopyUrl: true,
                    showExportMp4: true,
                    showFrameIndicator: true,
                    showGlobalMute: true,
                    showSceneSelector: true,
                    showWatchPreview: true,
                  }}
                  playerOptions={{
                    data: currentFormData,
                    playerVersionToUse: project.playerVersionToUse,
                    projectID: project.id,
                    useScenesFromContext: true,
                  }}
                />
              )}
            </div>
          </Content>
        </Layout>
      </Layout>
      {
        // Strange fix for the SDK player to always load on the modal
        isModalOpen.modalOpen && (
          <BlingsDynamicDataModal
            isModalOpen={isModalOpen.modalOpen}
            setIsModalOpen={setIsModalOpen}
            initialSelectedDataPointId={selectedDataPointId}
            initialSavedFormData={currentFormData}
            dataPointSelectorProps={dataPointSelectorProps}
            mode={isModalOpen.mode}
          />
        )
      }
    </OverviewPageContext.Provider>
  );
});

export default OverviewPage;
