import React, { useEffect, useMemo, useState } from "react";
import "./EnableOptimizationPanel.scss";
import { useMst } from "../../../../stores/Root";
import { StarsIcon } from "../../../../assets/Icons";
import { ExperimentOptimizationTechnique } from "../../../../API";

type Props = {
  setShouldShowOptimizationSelectionPanel: (value: boolean) => void;
};

export default function EnableOptimizationPanel({
  setShouldShowOptimizationSelectionPanel,
}: Props) {
  const {
    selectedExperimentOptmizationTechnique,
    setExperimentOptimizationTechnique,
    changeOptimizationTechnique,
  } = useMst((store) => {
    return {
      selectedExperimentOptmizationTechnique:
      store.projectsStore.selectedProject?.experiments?.selectedExperimentOptimizationTechnique,
      setExperimentOptimizationTechnique:
      store.projectsStore.selectedProject?.experiments?.setExperimentOptimizationTechnique,
      changeOptimizationTechnique:
        store.projectsStore.selectedProject?.changeExperimentOptimizationType,
    };
  });

  const [
    experimentOptimizationTechniqueIndex,
    _setExperimentOptimizationTechniqueIndex,
  ] = useState<number>(-1);

  // Function declarations
  function _changeOptimizationTechnique(index: number) {
    setExperimentOptimizationTechnique(optimizationOptions[index].value);
    changeOptimizationTechnique();
    setShouldShowOptimizationSelectionPanel(false);
  }

  // Extra logic
  const optimizationOptions = useMemo(
    () => [
      {
        title: "CONVERSION",
        description:
          "Track the success of your main call-to-action and drive results.",
        value: ExperimentOptimizationTechnique.CONVERSION,
        key: ExperimentOptimizationTechnique.CONVERSION,
      },
      {
        title: "ENGAGEMENT",
        description:
          "Monitor how users interact with your video to gauge interest.",
        value: ExperimentOptimizationTechnique.ENGAGEMENT,
        key: ExperimentOptimizationTechnique.ENGAGEMENT,
      },
      {
        title: "WATCH TIME",
        description: "Analyze how long viewers watch and stay engaged",
        value: ExperimentOptimizationTechnique.WATCH_TIME,
        key: ExperimentOptimizationTechnique.WATCH_TIME,
      },
      {
        title: "BLINGS FACTOR",
        description:
          "A balanced blend of key metrics to give a holistic performance score.",
        value: ExperimentOptimizationTechnique.BLINGS_MAGIC,
        key: ExperimentOptimizationTechnique.BLINGS_MAGIC,
      },
    ],
    []
  );

  return (
    <div className="optimization-panel-container">
      <div className="ai-enable-optimization-panel-header">
        <StarsIcon width={36} height={36} />
        <h3>Optimize this MP5 for:</h3>
      </div>
      <div className="ai-enable-optimization-panel-body">
        {optimizationOptions.map((option, index) => (
          <div
            key={option.key}
            className="ai-enable-optimization-panel-option"
            onClick={() => {
              _setExperimentOptimizationTechniqueIndex(index);
              _changeOptimizationTechnique(index);
            }}
          >
            <h2 className="ai-enable-optimization-panel-option-title">
              {option.title}
            </h2>
            <div className="ai-enable-optimization-panel-option-description">
              {option.description}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
