import * as React from "react";
import { Button, Table } from "antd";
import { PATHS, toPath } from "../../PATHS";
import { Link } from "react-router-dom";
import { IAccountModel, IFileUploadModel } from "../../stores/accountStore";
import { DeleteOutlined } from "@ant-design/icons";
import { ColumnProps } from "antd/lib/table";
import { IProjectModel } from "../../stores/project/projectModel";
import { IuploadDataModel } from "../../stores/uploadData";
import { observer } from "mobx-react-lite";
import { copy, getUrl } from "aws-amplify/storage";

type Props = {
  account: IAccountModel;
  projects: IProjectModel[];
  uploadData: IuploadDataModel;
  fileUploads: IFileUploadModel[];
};

const renderProject = (projects: IProjectModel[]) => (fileName: string) => {
  const connectedProjects = projects.filter((p) => p.fileName === fileName);

  if (connectedProjects.length > 0) {
    return (
      <>
        {connectedProjects.map((p, index) => (
          <>
            <Link to={toPath(PATHS.project, p.id)}>{p.title}</Link>
            <>{index < connectedProjects.length - 1 ? ", " : ""}</>
          </>
        ))}
      </>
    );
  }
  return <div>-</div>;
};

const renderFileName =
  (uploadData: IuploadDataModel) => (fileName: string, record: any) => {
    return (
      <span
        style={{ cursor: "pointer", color: "#363ef1" }}
        onClick={() => fetchSourceRecords(record.fileName, fileName)}
      >
        {fileName}
      </span>
    );
  };

/**
 * Creates a copy of the source record with the specified file name and downloads it.
 * The copy created has a time to live of 10 minutes.
 * @param sourceId The ID of the source file containing the .csv
 * @param fileName The name of the csv file to be used on the download
 */
const fetchSourceRecords = async (
  sourceId: string, // already has the .csv
  fileName: string
) => {
  // Make a copy of the file we're trying to download on the S3 with the following key: filename_sourceID
  const key = {
    key: `${sourceId}`,
  };
  const copyKey = {
    key: `copies/${fileName}_${sourceId}/${fileName}`,
  };

  // Copy the file with a time to live of 10 min from now
  await copy({
    source: key,
    destination: copyKey,
  });

  // Get the url of the copied file
  const { url } = await getUrl({
    key: copyKey.key,
    options: {
      validateObjectExistence: true,
      expiresIn: 60 * 10,
    },
  });

  // Create the download link and click it to start the download
  const element = document.createElement("a");
  element.setAttribute("href", url.toString());
  element.style.display = "none";
  document.body.appendChild(element);
  element.click();
};

export function renderUploadTime(time: string) {
  return new Date(time).toLocaleString();
}

const renderActions =
  (projects: IProjectModel[], account: IAccountModel) => (fileName: string) => {
    const hasConnectedProjects =
      projects.filter((p) => p.fileName === fileName).length > 0;
    return (
      <Button
        title={
          hasConnectedProjects
            ? "This data source is related to a project and can not be deleted"
            : "delete data source (un-recoverable)"
        }
        disabled={hasConnectedProjects}
        onClick={() => {
          const fileUploadsWithoutCurrent = account.fileUploads.filter(
            (f) => f.fileName !== fileName
          );
          account.updateFileUploads(fileUploadsWithoutCurrent, fileName);
        }}
      >
        <DeleteOutlined />
      </Button>
    );
  };

const DataUploadsTable = observer((props: Props) => {
  const cols: ColumnProps<any>[] = [
    { key: "originalName", dataIndex: "originalName", title: "Name" },
    { key: "fileName", dataIndex: "fileName", title: "ID" },
    { key: "createdAt", dataIndex: "createdAt", title: "Upload Time" },
    { key: "project", dataIndex: "fileName", title: "Projects" },
    { key: "delete", dataIndex: "fileName", title: "Delete" },
  ];

  cols[0].render = renderFileName(props.uploadData);
  cols[2].render = renderUploadTime;
  cols[3].render = renderProject(props.projects);
  cols[4].render = renderActions(props.projects, props.account);

  return (
    <Table
      columns={cols}
      rowKey={"fileName"}
      dataSource={props.fileUploads.slice().reverse()}
      pagination={{
        defaultPageSize: 10,
        showSizeChanger: true,
        hideOnSinglePage: true,
      }}
    />
  );
});

export default DataUploadsTable;
