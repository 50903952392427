import {
  NavLink,
  Outlet,
  useNavigate,
  useLocation,
  useParams,
  Link,
} from "react-router-dom";
import { toPath, PATHS, PROJECT_PATHS, camelCaseToRegular } from "../../PATHS";
import "./NavBar.scss";
import { IProjectModel } from "../../stores/project/projectModel";
import { observer } from "mobx-react-lite";
import { rootStore, useMst } from "../../stores/Root";
import NotificationBellWrapper from "../../components/notification/notificationBell";
import { Button, Layout, Dropdown, Popover, Menu, Tooltip } from "antd";
import { useEffect, useRef, useState } from "react";
import { AsyncOpState } from "../../types/enums/async-op-states";
import staticText from "../../utils/staticText";
import { dateFormatter } from "../../helpers/timeAgo.helpers";
import { getENV } from "../../config";
import useDebounce from "../../helpers/DebounceHook";
import {
  BlingsLogo,
  SettingsIcon,
  TimeIcon,
  VideoIcon,
} from "../../assets/Icons";
import { ProjectSearch } from "../ProjectsList/ProjectSearch";
import PlayerManager from "../../utils/playerManager";
import { TextButton } from "../../components/TextButton";
import { IFlowScene } from "@blings/blings-player/lib/src/SDK/sdk.api";
import SceneOrLineupSelect from "../../components/SceneOrLineupSelect";
import { usePlayerContext } from "../../contexts/playerContext";

type Props = {
  project: IProjectModel | undefined;
  projects: IProjectModel[];
  isLoading: boolean;
  operation: string | undefined;
  isPsychedelic: boolean;
  userEmail: string;
  setSearchView: (searchView: boolean) => void;
  setCurrentSearch: (searchString: string) => void;
  projectCurrentlyLoading: boolean;
  hasUnsavedChanges?: boolean;
  accountCan?: (permission: string) => boolean;
};
export const EXAMPLE_PLAYER_LINK = "https://assets.blings.io/player/index.html";

const NavBarWrapper = observer(() => {
  const { id } = useParams<{ id: string }>();
  const location = useLocation();
  // Get operation from url
  const operation = location.pathname.split("/")?.[3];
  const state = useMst((store) => {
    return {
      project: id ? store.projectsStore.selectedProject : undefined,
      hasUnsavedChanges: store.projectsStore.selectedProject?.hasUnsavedChanges,
      isLoading: store.projectsStore.projectIdCurrentlyLoading,
      operation,
      projects: store.projectsStore.projects,
      setSearchView: store.editVideoStore.setSearchView,
      setCurrentSearch: store.editVideoStore.setCurrentSearch,
      isPsychedelic: store.uiStore.isPsychedelic,
      projectCurrentlyLoading: store.projectsStore.projectIdCurrentlyLoading,
      userEmail: store.userStore.email,
      accountCan: store.userStore.accountFromId(
        store.projectsStore.selectedProject?.projectAccountId
      )?.accountCan,
    };
  });
  return <NavBar {...state} />;
});

const NavBar = ({
  project,
  projects: allProjects,
  operation,
  isPsychedelic,
  projectCurrentlyLoading,
  userEmail,
  accountCan,
}: Props) => {
  const history = useNavigate();

  const { setSelectedScenes, selectedScenes } = usePlayerContext();

  const minisiteConfig = project?.minisiteConfig;
  const videoPartNames = project?.videoPartNames;
  const selectedScenesFromMinisite = minisiteConfig?.scenes;
  const flowDiagram = project?.flowDiagram;
  const [hiddenNavItems, setHiddenNavItems] = useState<any>([]);
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [popoverLiveVersionOpen, setPopoverLiveVersionOpen] = useState(false);

  const navbarRef = useRef<HTMLDivElement>(null);
  const navItemsRef = useRef<HTMLDivElement>(null);

  const [navbarWidth, setNavbarWidth] = useState(0);
  const debouncedNavbarWidth = useDebounce(navbarWidth, 200); // 200ms debounce

  const widthStepsToItemsHide: { [key: number]: number } = {
    1200: 1,
    1100: 2,
    1000: 3,
    940: 4,
    800: 5,
    700: 6,
  };
  useEffect(() => {
    const handleResize = () => {
      if (navbarRef.current) {
        setNavbarWidth(navbarRef.current.offsetWidth);
      }
    };

    window.addEventListener("resize", handleResize);
    setTimeout(() => {
      handleResize(); // Initial call
    }, 2000);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [projectCurrentlyLoading]); // This effect should only run once on mount/unmount

  useEffect(() => {
    const navItemsChildren = Array.from(
      navItemsRef?.current?.children || []
    ).filter((item: any) => item.id !== "extraOptions");
    const tempHiddenNavItems: any[] = [];
    const tempVisibleNavItems: any[] = [];

    if (navItemsChildren) {
      let itemsToHide = 0;

      // Determine how many items to hide based on the width step.
      for (const width of Object.keys(widthStepsToItemsHide)) {
        if (debouncedNavbarWidth < Number(width)) {
          itemsToHide = widthStepsToItemsHide[Number(width)];
          break;
        }
      }

      Array.from(navItemsChildren).forEach((item, index) => {
        if (index >= navItemsChildren.length - itemsToHide) {
          tempHiddenNavItems.push(item);
        } else {
          tempVisibleNavItems.push(item);
        }
      });

      setHiddenNavItems(tempHiddenNavItems);

      // Handle display for nav items
      tempVisibleNavItems.forEach((item) => (item.style.display = "block"));
      tempHiddenNavItems.forEach((item) => (item.style.display = "none"));
    }
  }, [debouncedNavbarWidth, navItemsRef, popoverOpen]);

  const overLayLogo = [
    {
      key: 0,
      label: (
        <Link to={toPath(PATHS.data)}>
          <TextButton
            underline={false}
            // outline
            onClick={() => {}}
            text={"Data Uploads"}
          />
        </Link>
      ),
    },
    {
      key: 1,
      label: (
        <Link to={toPath(PATHS.settings)}>
          <TextButton
            // outline
            underline={false}
            onClick={() => {}}
            text={"Account Settings"}
          />
        </Link>
      ),
    },
    {
      key: 2,
      label: (
        <TextButton
          underline={false}
          // outline
          onClick={() => rootStore.userStore.signOut(history)}
          text={"Log Out"}
        />
      ),
    },
  ];
  const [finishedLoadingWatchScene, setFinishedLoadingWatchScene] =
    useState<boolean>(false);

  const [scenes, setScenes] = useState<IFlowScene[]>(() => {
    if (selectedScenesFromMinisite && selectedScenesFromMinisite.length > 0) {
      return selectedScenesFromMinisite.map((scene) => {
        try {
          return JSON.parse(scene);
        } catch (e) {
          return scene;
        }
      });
    }
    if (videoPartNames && videoPartNames.length > 0) return [videoPartNames[0]];
    return [];
  });

  // if the selectedScenes are not the same, set the selected scenes
  useEffect(() => {
    if (scenes && scenes.length > 0) {
      const scenesSet = new Set(scenes);
      const isContained = selectedScenes.every((scene) => scenesSet.has(scene));
      if (selectedScenes.length > 0 && isContained) {
        return;
      }
      setSelectedScenes(scenes);
    }
  }, [scenes, selectedScenes, setSelectedScenes]);

  // it takes a while for the scenes to load, so we need to wait for them to load before we can set the selected scenes
  useEffect(() => {
    if (scenes && scenes.length > 0) {
      setFinishedLoadingWatchScene(true);
    }
  }, [scenes]);

  // edge case: if the scenes are not loaded, set the scenes to the first video part name
  // but only if the scenes are not already set
  useEffect(() => {
    if (
      videoPartNames &&
      videoPartNames?.length > 0 &&
      !finishedLoadingWatchScene &&
      scenes?.length === 0
    ) {
      setScenes([videoPartNames[0]]);
    }
  }, [
    videoPartNames?.length,
    setScenes,
    videoPartNames,
    scenes,
    finishedLoadingWatchScene,
  ]);

  const [optimizationEnabled, setOptimizationEnabled] = useState(
    accountCan ? accountCan("aiOptimization") : false
  );

  const handleSetScenes = (scenes: IFlowScene[]) => {
    setScenes(scenes);
    setSelectedScenes(scenes);
  };

  useEffect(() => {
    if (project && project.minisiteConfig && project.minisiteConfig.scenes) {
      const scenes =
        project.minisiteConfig.scenes?.map((sceneOrLineup) => {
          try {
            return JSON.parse(sceneOrLineup);
          } catch (e) {
            return sceneOrLineup;
          }
        }) || [];
      setScenes(scenes);
      setSelectedScenes(scenes);
    }
    if (project?.account?.id) {
      setOptimizationEnabled(accountCan?.("aiOptimization") || false);
    }
  }, [project]);

  const [publishButtonState, setPublishButtonState] = useState<AsyncOpState>(
    AsyncOpState.Changed
  );
  const publishButtonTexts = {
    [AsyncOpState.Changed]: "Publish",
    [AsyncOpState.Saving]: "Publishing",
    [AsyncOpState.Success]: "Published",
    [AsyncOpState.Error]: "Error",
  };
  const lastPublishedAt = project?.publishedAt || project?.updatedAt || "";

  /**
   *  Check if project has nav item to render in the navbar
   * @param navItem
   * @returns
   */
  const projectHasNavItem = (navItem: string) => {
    switch (navItem) {
      case PROJECT_PATHS.customAnalytics:
        return !!project?.analyticsReportUrl;
      case PROJECT_PATHS.analytics:
        return project?.analyticsEnabled && !project.analyticsReportUrl;
      case PROJECT_PATHS.liveControl:
        let hasControl = false;
        try {
          if (project?.settingsJsonSchemaStr) {
            const control = JSON.parse(project.settingsJsonSchemaStr);
            if (control && control.properties) {
              hasControl = JSON.stringify(control.properties) !== "{}";
            }
          }
        } catch (e) {
          console.error(e);
        }
        return !!hasControl;
      case PROJECT_PATHS.formData:
        if (!project) return false;
        return project.hasFormData;
      case PROJECT_PATHS.optimization:
        return optimizationEnabled;
      default:
        return true;
    }
  };
  function openLiveVersion() {
    let url = `${EXAMPLE_PLAYER_LINK}?env=${getENV()}&p=${project?.id || ""}`;
    if (scenes) {
      url += `&scenes=${encodeURIComponent(
        scenes
          .map((scene) =>
            typeof scene === "string" ? scene : JSON.stringify(scene)
          )
          .join()
      )}`;
    }
    const publishedExampleData = project?.stateJsonSchemaStr
      ? JSON.parse(project.stateJsonSchemaStr).examples[0]
      : null;
    if (publishedExampleData) {
      url += `&data=${encodeURIComponent(
        JSON.stringify(publishedExampleData)
      )}`;
    }

    const currentVersion = PlayerManager.get().GetCurrentPlayerVersion();
    // Do nothing if the player has the latest version
    if (currentVersion === "latest") {
    }
    // If there is no player version, use the one that the project has specified
    else if (currentVersion === "WARN_NO_PLAYER_VERSION") {
      console.warn("No player version specified, using project version");
      console.warn("Project version: ", project?.playerVersionToUse);
      url += `&v=${(project?.playerVersionToUse as string).replaceAll(
        ".",
        "-"
      )}`;
    }
    // If there is a player version, use that
    else {
      url += `&v=${currentVersion}`;
    }

    window.open(url, "_blank");
  }
  const Title = (
    title: string,
    text: string,
    showSceneSelect: Function = () => false
  ) => {
    const validTitle = projectCurrentlyLoading
      ? "Loading Project..."
      : title || "Loading Project...";
    return (
      <div className="title-container">
        <div className="title-wrapper">
          <span className="title">{validTitle}</span>
          <span className="sub-title">{text}</span>
        </div>
        {showSceneSelect() && project && (
          <div className="scene-selector">
            <span>Watching</span>
            <SceneOrLineupSelect
              flowDiagram={flowDiagram}
              scenes={videoPartNames || []}
              value={scenes}
              onChange={handleSetScenes}
              className="view-live-version-select"
              limit={true}
            />
          </div>
        )}
      </div>
    );
  };
  const getPageTitle = () => {
    switch (operation) {
      case PROJECT_PATHS.editContent:
        return Title(
          "Edit Content",
          "Improve your content globally for all viewers"
        );
      case PROJECT_PATHS.overview:
        return Title(
          project?.title || "",
          "Overview of your project's essentials",
          () => true
        );
      case PROJECT_PATHS.settings:
        return Title(
          "Settings",
          "Adjust project details, player options, and MP5 data structure",
          () => {
            // If path contains settings/playerSettings, show scene selector
            if (window.location.pathname.includes("playerSettings"))
              return true;
            return false;
          }
        );
      case PROJECT_PATHS.integration:
        return Title(
          "Integration",
          "Integrate MP5 videos into your CRM and custom HTML pages"
        );
      case PROJECT_PATHS.analytics:
        return Title(
          "Analytics",
          "View insights on MP5 performance & audience behavior"
        );
      case PROJECT_PATHS.formData:
        return Title(
          "Form Data",
          "Review and export insights from your viewers"
        );
      case PROJECT_PATHS.optimization:
        return Title("AI Optimization", "Improve your MP5 results with AI");
      default:
        return "";
    }
  };

  return (
    <>
      <Layout>
        <Layout.Header className={"Main Header"}>
          <div className={"NavBar"} ref={navbarRef}>
            <div className="left">
              <Link to={"/"} className="LogoLink">
                <div
                  style={{
                    color: isPsychedelic ? "orange" : "#ff3666",
                    display: "flex",
                  }}
                >
                  <BlingsLogo />
                </div>
              </Link>
              {project ? (
                <>
                  <ProjectSearch
                    projectCurrentlyLoading={projectCurrentlyLoading}
                    selectedProject={project}
                    projects={allProjects}
                  />
                  <div className="menu-items" ref={navItemsRef}>
                    {Object.entries(PROJECT_PATHS).map(
                      (navItem) =>
                        projectHasNavItem(navItem[1]) && (
                          <NavLink
                            key={toPath(navItem[1])}
                            id={camelCaseToRegular(navItem[0])}
                            to={toPath(PATHS.project, project.id, navItem[1])}
                            className={({ isActive }) =>
                              [
                                "NavItem",
                                isActive
                                  ? // ||
                                    // (operation === "edit-video" &&
                                    //   navItem[1] === "edit-video")
                                    "Selected"
                                  : "",
                              ].join(" ")
                            }
                          >
                            {camelCaseToRegular(navItem[0])}
                          </NavLink>
                        )
                    )}
                    {hiddenNavItems.length ? (
                      <Dropdown
                        menu={{
                          items: hiddenNavItems.map((item: any) => ({
                            key: item.id,
                            label: (
                              <NavLink to={item.getAttribute("href")}>
                                {item.textContent}
                              </NavLink>
                            ),
                          })),
                        }}
                      >
                        <div
                          id="extraOptions"
                          style={{ fontSize: "20px", cursor: "pointer" }}
                        >
                          ...
                        </div>
                      </Dropdown>
                    ) : null}
                  </div>
                </>
              ) : (
                <div></div>
              )}
            </div>
            <div className="RightNav">
              {project ? (
                <>
                  <div
                    id="ExtraInfo-long"
                    title={new Date(lastPublishedAt).toString()}
                  >
                    {staticText.header.VERSION(project.isLiveVersion)}{" "}
                    {staticText.header.LAST_EDIT}
                    {lastPublishedAt
                      ? dateFormatter(new Date(lastPublishedAt))
                      : "done"}{" "}
                    {/* {staticText.header.BY_USER("User")} */}
                  </div>
                  <div id="ExtraInfo-short">
                    <Tooltip
                      placement="bottomLeft"
                      title={new Date(lastPublishedAt).toString()}
                    >
                      <div style={{ display: "flex" }}>
                        <TimeIcon />
                      </div>
                    </Tooltip>
                  </div>

                  <div id="PreviewButton" className="previewPublished-long">
                    <Popover
                      overlayClassName="view-live-version-popover"
                      placement="bottomRight"
                      title="Pick a Flow:"
                      open={popoverLiveVersionOpen}
                      onOpenChange={setPopoverLiveVersionOpen}
                      content={
                        <div>
                          <SceneOrLineupSelect
                            flowDiagram={flowDiagram}
                            scenes={videoPartNames || []}
                            value={scenes}
                            onChange={setScenes}
                            className="view-live-version-select"
                          />
                          <Button type="primary" onClick={openLiveVersion}>
                            Watch
                          </Button>
                        </div>
                      }
                      trigger="click"
                    >
                      <div className="previewPublished-long">
                        <Tooltip
                          title="Watch Published Version"
                          placement="bottom"
                        >
                          <Button className="preview" type="text">
                            <VideoIcon />
                          </Button>
                        </Tooltip>
                      </div>
                      <div className="previewPublished-short">
                        <Tooltip
                          title="Watch Published Version"
                          placement="bottom"
                        >
                          <Button className="preview" type="text">
                            <VideoIcon />
                          </Button>
                        </Tooltip>
                      </div>
                    </Popover>
                  </div>

                  <div className="notificationIcon">
                    <NotificationBellWrapper />
                  </div>
                  <Dropdown menu={{ items: overLayLogo }} trigger={["click"]}>
                    <div className="settings">
                      <SettingsIcon />
                    </div>
                  </Dropdown>
                </>
              ) : (
                <>
                  <div className="user-email">{userEmail}</div>
                  <div className="notificationIcon">
                    <NotificationBellWrapper />
                  </div>
                  <Dropdown menu={{ items: overLayLogo }} trigger={["click"]}>
                    <div className="settings" id="account-dropdown">
                      <SettingsIcon />
                    </div>
                  </Dropdown>
                </>
              )}
            </div>
          </div>
        </Layout.Header>
        <Layout.Content className={"Main-Content"}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              height: "100%",
            }}
          >
            {getPageTitle()}

            <Outlet />
          </div>
        </Layout.Content>
      </Layout>
    </>
  );
};

export default NavBarWrapper;
