import { types, Instance, getRoot, flow, cast } from "mobx-state-tree";
import { signOut } from "aws-amplify/auth";
import * as APITypes from "../API";
import { generateClient, get } from "aws-amplify/api";
import { copy, remove } from "aws-amplify/storage";
import { PATHS, toPath } from "../PATHS";
import { AccountTypeName, UpdateAccountMutation } from "../API";
import { updateAccount } from "../graphql/mutations";
import {
  NEW_REST_API,
  NEW_REST_API__V2__ACCOUNT_ALIAS_AVAILABLE,
} from "../consts";
import { AccountPermissionsProps, FileUploadProps } from "./platformSchema";
import { IRootModel } from "./Root";
import { NavigateFunction } from "react-router";
import { asyncOpStateType } from "./async-op-states";
import { AsyncOpState } from "../types/enums/async-op-states";
import { AuthSession } from "../types/awsTypes";
import { GeneratedQuery } from "@aws-amplify/api-graphql";

// Graphql Client
const client = generateClient();

const accountPermissionsModel = types.model({
  ...AccountPermissionsProps,
});
export type IAccountPermissionsModel = Instance<typeof accountPermissionsModel>;

const AccountTypeModel = types.model({
  id: types.identifier,
  levelName: types.enumeration(["FREEMIUM", "PRO", "ENTERPRISE"]),
  permissions: accountPermissionsModel,
});
export type IAccountTypeModel = Instance<typeof AccountTypeModel>;
const userAttributesModel = types.model({
  sub: types.string,
  identityId: types.string,
  email: types.string,
  phone_number: types.maybeNull(types.string),
  "custom:mfaConfig": types.maybeNull(types.string),
});
export type IUserAttributesModel = Instance<typeof userAttributesModel>;

const FileUploadModel = types.model({
  ...FileUploadProps,
  createdAt: types.string,
  fileName: types.string,
});
export type IFileUploadModel = Instance<typeof FileUploadModel>;
export type IFileUploadsModel = Instance<typeof FileUploadModel>[];

export const AccountModel = types
  .model("Account", {
    id: types.string,
    name: types.string,
    createdAt: types.string,
    updatedAt: types.string,
    aliasId: types.string,
    minisiteDomain: types.optional(
      types.union(types.string, types.null, types.undefined),
      ""
    ),
    mfaEnabled: types.optional(types.maybeNull(types.boolean), false),
    createNewVideosWithBlingLogo: types.optional(
      types.maybeNull(types.boolean),
      true
    ),
    fileUploads: types.optional(types.array(FileUploadModel), []),
    saveAliasIdStatus: asyncOpStateType,
    fonts: types.optional(
      types.array(
        types.maybe(
          types.model({
            fontFamily: types.string,
            variants: types.array(
              types.model({
                name: types.string,
                weight: types.number,
                style: types.string,
              })
            ),
          })
        )
      ),
      []
    ),
    accountType: types.maybe(
      types.maybeNull(
        types.model({
          levelName: types.maybe(
            types.maybeNull(
              types.enumeration(
                "AccountTypeName",
                Object.keys(AccountTypeName) as Array<
                  keyof typeof AccountTypeName
                >
              )
            )
          ),
          permissions: types.optional(
            types.maybe(
              types.maybeNull(
                types.model({
                  removeBlingLogo: types.maybe(types.maybeNull(types.boolean)),
                  aiOptimization: types.maybe(types.maybeNull(types.boolean)),
                })
              )
            ),
            {}
          ),
        })
      )
    ),
    uploadFontStatus: asyncOpStateType,
    loadStatus: types.optional(
      types.enumeration("LoadStatus", ["unloaded", "loading", "loaded"]),
      "unloaded"
    ),
  })
  .volatile((self) => ({
    loadCallbackFunctions: [] as (() => void)[],
  }))
  .views((self) => ({
    accountCan(permission: keyof IAccountPermissionsModel): boolean {
      const accountType = self.accountType;
      return accountType?.permissions?.[permission] || false;
    },
  }))
  .actions((self) => ({
    setFileUploads(fileUploads: Array<IFileUploadModel>) {
      self.fileUploads.replace(fileUploads);
    },
    setSaveAliasIdStatus(status: AsyncOpState) {
      self.saveAliasIdStatus = status;
    },
  }))
  .actions((self) => ({
    async signOut(history: NavigateFunction) {
      try {
        await signOut();
        getRoot<IRootModel>(
          self
        ).projectsStore.removeProjectsFromLocalStorage();
        getRoot<IRootModel>(self).resetStore();
        history(toPath(PATHS.home));
        window.location.reload();
      } catch (e) {
        console.log(e, "failed to log out");
      }
    },
    saveAliasId: flow(function* (aliasId: string) {
      try {
        self.setSaveAliasIdStatus(AsyncOpState.Saving);
        yield get({
          apiName: NEW_REST_API,
          path: `${NEW_REST_API__V2__ACCOUNT_ALIAS_AVAILABLE(
            self.id as string,
            aliasId
          )}`,
        }).response;

        const input = { id: self.id as string, aliasId };
        const resp = (yield client.graphql({
          query: updateAccount,
          variables: { input },
        })) as {
          data: UpdateAccountMutation & {
            updateAccount: { minisiteDomain: string | null };
          };
        };
        if (resp.data.updateAccount) {
          if (!resp.data.updateAccount.minisiteDomain) {
            resp.data.updateAccount.minisiteDomain = "";
          }
          self.minisiteDomain = resp.data.updateAccount.minisiteDomain;
          self.aliasId = resp.data.updateAccount.aliasId;
        } else {
          throw new Error("");
        }
        self.setSaveAliasIdStatus(AsyncOpState.Success);
      } catch (e) {
        self.setSaveAliasIdStatus(AsyncOpState.Error);
      }
      setTimeout(() => {
        self.setSaveAliasIdStatus(AsyncOpState.Changed);
      }, 2000);
    }),
    refreshFileUploads: flow(function* () {
      const query = /* GraphQL */ `
        query GetAccount($id: ID!) {
          getAccount(id: $id) {
            id
            fileuploads {
              originalName
              createdAt
              fileName
              fileStatus
              fileError
              numRecords
              numErrors
              totalRecords
              writtenRecords
            }
          }
        }
      ` as GeneratedQuery<
        APITypes.GetAccountQueryVariables,
        {
          getAccount?: {
            id: string;
            fileuploads?: Array<{
              originalName?: string | null;
              createdAt: string;
              fileName: string;
              fileStatus?: string | null;
              fileError?: string | null;
              numRecords?: number | null;
              numErrors?: number | null;
              totalRecords?: number | null;
              writtenRecords?: number | null;
            } | null> | null;
          };
        }
      >;
      const response = (yield client.graphql({
        query,
        variables: { id: self.id },
      })) as {
        data: {
          getAccount: { fileuploads: Array<IFileUploadModel>; id: string };
        };
      };
      response.data?.getAccount?.fileuploads &&
        self.setFileUploads(response.data.getAccount.fileuploads);
    }),
    /**
     * Update the account linked CSV files and set the fileToDelete to a temp folder on S3 to be deleted in 7 days
     * @param updatedFileNames A array of files to update the account information with
     * @param fileToDelete The file that was set to be deleted
     */
    async updateFileUploads(updatedFileNames: any, fileToDelete: string) {
      const input = {
        id: self.id as string,
        fileuploads: updatedFileNames,
      };

      const resp = (await client.graphql(
        {
          query: updateAccount,
          variables: { input },
        }
        // graphqlOperation(updateAccount, { input })
      )) as { data: UpdateAccountMutation };
      if (resp.data.updateAccount) {
        self.setFileUploads(updatedFileNames);
      } else {
        throw new Error("");
      }

      // After updating the account information on DynamoDB, move the CSV file from the S3 public folder to the S3 temp folder
      // The temp folder has this format: public/temp/account's cognito group id/file name
      // The deletion of the file will be handled by a lifecicle rule already implemented on S3

      // Copy the file to the temp folder
      const from = { key: fileToDelete };
      const to = { key: "temp/" + self.id + "/" + fileToDelete };
      await copy({
        source: from,
        destination: to,
      });
      // Delete the file from the public folder
      await remove({
        key: from.key,
      });
    },
    /* async connectHubspot(data: ITokenResponseIF) {
      self.hubspotConnection = JSON.stringify({
        ...data,
        updatedAt: Date.now(),
      });
      const input = {
        id: self.cognitoGroupId as string,
        integrations: { hubspot: self.hubspotConnection },
      };
      (await client.graphql({
        query: updateAccount,
        variables: { input },
      })) as {
        data: UpdateAccountMutation;
      };
    }, */
    /* async disconnectHubspot() {
      self.hubspotConnection = null;
      const input = {
        id: self.cognitoGroupId as string,
        integrations: { hubspot: self.hubspotConnection },
      };
      (await client.graphql({
        query: updateAccount,
        variables: { input },
      })) as {
        data: UpdateAccountMutation;
      };
    }, */
  }));
/* .actions((self) => ({
    async refreshHubspotCredentials() {
      const myInit = {
        crossDomain: true,
        response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
        body: {
          refreshToken: JSON.parse(self.hubspotConnection || "").refreshToken,
        },
      };
      const data: any = await post({
        apiName: NEW_REST_API,
        path: NEW_REST_API__V1__HUBSPOT_REFRESH_TOKEN,
        options: myInit,
      }).response;
      await self.connectHubspot(data);
    },
  })); */

export type IAccountModel = Instance<typeof AccountModel>;
