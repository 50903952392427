import { getENV } from "./config";
import { AsyncOpState } from "./types/enums/async-op-states";

export const PLATFORM_QS_CONTROL_STATE = "__blings__platform__state"; // should be same as in player
//export const PLATFORM_REST_API = "platformRest";
//export const PLATFORM_REST_API__PATH = "/platform";
//export const PLATFORM_REST_API__DATA_RECODRS_PATH = PLATFORM_REST_API__PATH + "/datarecords";
export const ADMIN_GROUP = "blingsadmins";
export const DEMOS_PLAYER_LINK =
  "https://assets.blings.io/projects/demos/index.html";
export const EXAMPLE_PLAYER_LINK = "https://assets.blings.io/player/index.html";
export const DYNAMIC_THUMBNAIL_BASE_URL =
  getENV() === "dev" ? "https://devt.blings.io" : "https://t.blings.io";
export const FEEDBACK_TIME = 1000;
export const FEEDBACK_OPTIONS = {
  [AsyncOpState.Changed]: "Save",
  [AsyncOpState.Saving]: "Saving",
  [AsyncOpState.Success]: "Saved",
  [AsyncOpState.Error]: "Error",
};

export const NEW_REST_API = "BlingsAPI";
export const NEW_REST_API__V1__SERVER_RENDER_REQUEST_RENDER =
  "/v1/private/server-render";
export const OLD_REST_API__V1__DATA_RECORDS_PATH =
  "/v1/private/account/project/datapoint/"; // + dataId
export const NEW_REST_API__V1__HUBSPOT_REQUEST_ACCESS_TOKEN =
  "/v1/private/integration/hubspot/requestAccessToken";
export const NEW_REST_API__V1__HUBSPOT_REFRESH_TOKEN =
  "/v1/private/integration/hubspot/requestAccessToken";
export const OLD_REST_API__v1__UPLOAD_CSV = "/v1/private/account/csv";
export const NEW_REST_API__V2__ANALYTICS_SESSIONS_PATH = (projectId: string) =>
  `/v2/private/project/${projectId}/analytics`;
export const REST_API_V2__GET_USE_CASES = "/v2/private/useCases";
export const REST_API_V2__INSTANTIATE_USE_CASE = (
  accountId: string,
  useCaseId: string
) => `/v2/private/useCases/${accountId}/${useCaseId}`;
export const NEW_REST_API__V2__ACCOUNT_ALIAS_AVAILABLE = (
  account: string,
  alias: string
) => `/v2/private/account/${account}/aliasAvailable/${alias}`;
export const NEW_REST_API__V2__PROJECT_ALIAS_AVAILABLE = (
  projectId: string,
  aliasId: string
) => `/v2/private/project/${projectId}/aliasAvailable/${aliasId}`;
export const NEW_REST_API__V2__UTILS__GENERATE_SCREENSHOT = (
  projectId: string
) => `/v2/private/project/${projectId}/generateThumbnail`;
export const NEW_REST_API__V2__DOWNLOAD_FORM_DATA = (projectId: string) =>
  `/v2/private/project/${projectId}/formData`;
export const NEW_REST_API__V2__UPLOAD_CSV = (account: string) =>
  `/v2/private/account/${account}/csv`;
export const NEW_REST_API__V2__DATA_RECORDS_PATH = (projectId: string) =>
  `/v2/private/project/${projectId}/dataPoint`;
export const NEW_REST_API__V2__DATA_POINT_RECORDS_PATH = (
  projectId: string,
  dataId: string
) => `/v2/private/project/${projectId}/dataPoint/${dataId}`;
export const NEW_REST_API__V2__DATA_POINT_RENAME = (projectId: string) =>
  `/v2/private/project/${projectId}/dataPoint`;
export const MICROSERVICE_API_URLS = {
  font: {
    dev: "https://5ptkfkesj2.execute-api.eu-west-1.amazonaws.com/dev/v1/public/convert/font",
    master:
      "https://2bzp4hwfqj.execute-api.eu-west-1.amazonaws.com/master/v1/public/convert/font",
    staging:
      "https://2bzp4hwfqj.execute-api.eu-west-1.amazonaws.com/master/v1/public/convert/font",
  }[getENV()],
  image: {
    dev: "https://tee1st7f3d.execute-api.eu-west-1.amazonaws.com/dev/v1/private/convert/image",
    master:
      "https://xtt82dfuj8.execute-api.eu-west-1.amazonaws.com/master/v1/private/convert/image",
    staging:
      "https://xtt82dfuj8.execute-api.eu-west-1.amazonaws.com/master/v1/private/convert/image",
  }[getENV()],
};

export const FREEMIUM_PERMISSIONS = {
  removeBlingLogo: false,
  aiOptimization: false,
};

export const PRO_PERMISSIONS = {
  removeBlingLogo: true,
  aiOptimization: true,
};

export const ENTERPRISE_PERMISSIONS = {
  removeBlingLogo: true,
  aiOptimization: true,
};
