import "./DynamicThumbnailForm.scss";
import { useState, useEffect } from "react";
import InputWithTags from "./DynamicThumbnailFormComponents/InputWithTags";
import { Divider, Popover, Select, Tabs, UploadProps } from "antd";
import { Input, Button, Upload } from "antd";
import { useMst } from "../../../../../stores/Root";
import useDebounce from "../../../../../helpers/DebounceHook";
import { produce } from "immer";
import ThumbnailPlayButton from "./DynamicThumbnailFormComponents/ThumbnailPlayButton";
import { QuestionCircleOutlined, UploadOutlined } from "@ant-design/icons";
import { observer } from "mobx-react-lite";
import LoadingSpinner from "../../../../../assets/Ellipse 7.png";

import {
  previewThumbnail,
  updateOrCreateThumbnail,
} from "../../../../../stores/project/dynamicThumbnailTools";
import {
  DYNAMIC_THUMBNAIL_BASE_URL,
  FEEDBACK_OPTIONS,
} from "../../../../../consts";
import { IDynamicThumbnailModel } from "../../../../../stores/project/DynamicThumbnailModel";
import { AsyncOpState } from "../../../../../types/enums/async-op-states";
import { BlingsBtn } from "../../../../../components/antd-extensions/blings-btn.component";
import { crms } from "../CrmIntegration";
import { getRealENV } from "../../../../../config";
import { flattenSchema } from "../../../../../helpers/jsonSchema.helpers";
import { rootStore } from "../../../../../stores/Root";

export type ConfigV2 = {
  version: 2;
  playIcon: {
    enabled: boolean;
    size: number;
    color: string;
    opacity: number;
    maxSize?: number;
  };
  texts: Array<{
    breakpoint: number;
    position: {
      x: 50;
      y: number;
    };
    anchor: "middle";
    color: string;
    stroke: { color: string; thickness: number; opacity: number };
    weight: string;
    fontFamily: string;
    fontSize: number;
    textTemplate: Array<string | { id: string; title?: string }>;
  }>;
  width: number;
  height: number;
  previewParams: Array<string>;
  projectId: string;
};

export const DynamicThumbnailForm = observer(() => {
  const [image, setImage] = useState<string | null>(null);
  const [isChanging, setIsChanging] = useState<boolean>(false);
  const [token, setToken] = useState<any>(null);
  const [selectedCrmIndex, setSelectedCrmIndex] = useState(0);
  const selectedCrm = crms[selectedCrmIndex];
  const [saveState, setSaveState] = useState<AsyncOpState>(
    AsyncOpState.Changed
  );
  const {
    projectsStore: {
      selectedProject: {
        aliasId: projectAliasId,
        account: { aliasId },
        stateJsonSchema,
        projectAccountDomain,
        dynamicThumbnail: {
          id,
          saveDynamicThumbnailId,
          configV2: config,
          file: fileData,
          setConfig,
          handleFileChange,
          changeDynamicThumbnailStatus,
          DynamicThumbStatus,
        },
      },
    },
  } = useMst() as {
    projectsStore: {
      selectedProject: {
        aliasId: string;
        account: { aliasId: string };
        stateJsonSchema: any;
        dynamicThumbnail: IDynamicThumbnailModel;
        projectAccountDomain: string;
      };
    };
  };

  const getAuthorizationHeaderValue = async () => {
    try {
      const authSession = rootStore.userStore.session;
      const accessToken = authSession.accessToken.toString() as string;
      setToken(accessToken);
    } catch (error) {
      console.error("Error retrieving authorization header value:", error);
    }
  };

  useEffect(() => {
    (async () => {
      await getAuthorizationHeaderValue();
    })();
  });

  const handleSubmit = async (e: any) => {
    setSaveState(AsyncOpState.Saving);
    e.preventDefault();
    if (!fileData) {
      alert("You must submit a valid PNG file");
      return;
    }
    try {
      if (!config) return;
      const resJSON = await updateOrCreateThumbnail(id, {
        config,
        file: fileData,
      });
      const thumbID = resJSON.id;
      saveDynamicThumbnailId(thumbID);
      console.log("Form submitted");
      setSaveState(AsyncOpState.Success);
    } catch (err) {
      console.log(err);
      setSaveState(AsyncOpState.Error);
    }
  };

  const debouncedConfig = useDebounce<any>(config, 750);
  useEffect(() => {
    (async () => {
      setSaveState(AsyncOpState.Changed);
      if (fileData && token) {
        try {
          changeDynamicThumbnailStatus(AsyncOpState.Saving);
          const response = await previewThumbnail(debouncedConfig, fileData);
          if (response && response.status === 200) {
            const imageBase64 = await response.text();
            setImage(imageBase64);
          }
          changeDynamicThumbnailStatus(AsyncOpState.Success);
        } catch (err) {
          changeDynamicThumbnailStatus(AsyncOpState.Error);
          console.log(err);
        }
      }
    })();
  }, [debouncedConfig, token]);

  const handleInputChange = (num: number, e: any) => {
    const nextState = produce(config, (draft: { previewParams: string[] }) => {
      if (!config || !debouncedConfig) return;
      draft.previewParams[num] = e.target.value;
    });
    if (nextState) setConfig(nextState);
  };

  const uploadProps: UploadProps = {
    name: "file",
    maxCount: 1,
    showUploadList: {
      showRemoveIcon: false,
      showPreviewIcon: false,
    },
    accept: "image/png",
    customRequest(info) {
      handleFileChange(info, info.onSuccess, info.onError);
    },
  };
  const env = getRealENV();
  const paramsMap = stateJsonSchema
    ? flattenSchema(stateJsonSchema)
    : { blingsId: "CSV_ID" };
  const paramsHtml = Object.keys(paramsMap)
    .map((key, i) => {
      const openingTag = selectedCrm.tags?.openingTag || "{{";
      const closingTag = selectedCrm.tags?.closingTag || "}}";
      const token = `${openingTag}${encodeURIComponent(
        key.toUpperCase()
      )}${closingTag}`;

      return `${i !== 0 ? "&" : ""}${encodeURIComponent(key)}=${token}`;
    })
    .join("");

  const paramsHtmlJsx = Object.keys(paramsMap).map((key, i) => {
    return (
      <span key={key}>
        {i !== 0 ? "&" : ""}
        {encodeURIComponent(key)}=
        <span className="token">{`${
          selectedCrm.tags?.openingTag || "{{"
        }${encodeURIComponent(key.toUpperCase())}${
          selectedCrm.tags?.closingTag || "}}"
        }`}</span>
      </span>
    );
  });
  const crmLink = `https://${aliasId}.${projectAccountDomain}/${
    env === "master" ? "" : `${env}/`
  }${projectAliasId}?${paramsHtml}`;
  const crmLinkJsx = (
    <span>
      {`https://${aliasId}.${projectAccountDomain}/${
        env === "master" ? "" : `${env}/`
      }${projectAliasId}?`}
      {paramsHtmlJsx}
    </span>
  );
  const dynamicLinkJSX = (tags: { openingTag: string; closingTag: string }) => (
    <>
      {config?.texts.some((text) =>
        text.textTemplate.some(
          (el) => typeof el !== "string" && el.id === "param1"
        )
      ) ? (
        <span className="token">{`${tags.openingTag}Param1${tags.closingTag}`}</span>
      ) : (
        <></>
      )}
      {config?.texts.some((text) =>
        text.textTemplate.some(
          (el) => typeof el !== "string" && el.id === "param2"
        )
      ) ? (
        <>
          /
          <span className="token">{`${tags.openingTag}Param2${tags.closingTag}`}</span>
        </>
      ) : (
        <></>
      )}
    </>
  );
  const dynamicLink = (tags: { openingTag: string; closingTag: string }) => {
    let result = "";

    if (
      config?.texts.some((text) =>
        text.textTemplate.some(
          (el) => typeof el !== "string" && el.id === "param1"
        )
      )
    ) {
      result += `${tags.openingTag}Param1${tags.closingTag}`;
    }

    if (
      config?.texts.some((text) =>
        text.textTemplate.some(
          (el) => typeof el !== "string" && el.id === "param2"
        )
      )
    ) {
      result += `/${tags.openingTag}Param2${tags.closingTag}`;
    }

    return result;
  };
  const items = [
    {
      label: "HTML Code",
      key: "1",
      children: (
        <div>
          <div style={{ lineHeight: "28px" }}>
            1. Copy the link below and go to your CRM platform.
            <br />
            2. Within your CRM, create a template or an email campaign.
            <br />
            3. Within the email editor, select the HTML module and insert it
            into the template.
            <br />
            4. Within the HTML block, add the Blings code copied from step one.
            <br />
            5. Update all other variables{" "}
            <span style={{ color: "#ff3666" }}>(in pink)</span> to align with
            your CRM.
            <br />
            For example:{" "}
            {"Replace “{{NAME}}” and “{{Param1}}” with “{{contact.firstname}}”"}
            <br />
          </div>
          <div
            className="BlingsContainer main-link-container"
            style={{ ...(!id && { opacity: "0.5" }), margin: "1rem 0" }}
          >
            <div>Copy your integration link and customize your tokens</div>
            <div className="link-container">
              <div className="link">
                <span>{`<a href="`}</span>
                <span>{crmLinkJsx}</span>
                <span>{`">`}</span> <br />
                <span>{`  <img src="`}</span>
                <span>
                  {id && `${DYNAMIC_THUMBNAIL_BASE_URL}/${id}/`}
                  {id && dynamicLinkJSX(selectedCrm.tags)}
                </span>
                <span>
                  {`" alt="Image 1" style="display: block; margin: 0 auto;">`}
                </span>
                <span>
                  {" "}
                  <br />
                  {` </a>`}
                </span>
              </div>
            </div>
            <div className="copy-extra-information">
              <div>
                <span style={{ fontWeight: "700" }}>Important: </span>Ensure all
                changes in the thumbnail editor are saved before copying
              </div>
              <Button
                disabled={!id || !config}
                onClick={() => {
                  id &&
                    config &&
                    navigator.clipboard.writeText(
                      `<a href="${crmLink}">
    <img src="${DYNAMIC_THUMBNAIL_BASE_URL}/${id}/${dynamicLink(
                        selectedCrm.tags
                      )}" alt="Image 1" style="display: block; margin: 0 auto;">
</a>`
                    );
                }}
              >
                Copy
              </Button>
            </div>
          </div>
        </div>
      ),
    },
    {
      label: "Thumbnail Url",
      key: "2",
      children: (
        <div
          className="BlingsContainer main-link-container"
          style={{ ...(!id && { opacity: "0.5" }) }}
        >
          <div>Copy your integration link and customize your tokens</div>
          <div>
            <div className="link-container">
              <div className="link">
                {id && `${DYNAMIC_THUMBNAIL_BASE_URL}/${id}/`}
                {id && dynamicLinkJSX(selectedCrm.tags)}
              </div>
            </div>
            <div className="copy-extra-information">
              <Button
                disabled={!id || !config}
                onClick={() => {
                  id &&
                    config &&
                    navigator.clipboard.writeText(
                      `${DYNAMIC_THUMBNAIL_BASE_URL}/${id}/` +
                        dynamicLink(selectedCrm.tags)
                    );
                }}
              >
                Copy
              </Button>
            </div>
          </div>
        </div>
      ),
    },
  ];

  return (
    <div className="dynamic-thumb-form">
      <div className="titles">
        <div className="header">Create a new Dynamic Thumbnail</div>

        <div className="sub-text">
          To add a personalized touch to your CRM emails, upload an image here.
          This thumbnail will dynamically change based on your selected
          customization parameters for each recipient. Simply click 'Upload',
          select your base image, and follow the prompts to set personalization
          features.
        </div>
      </div>
      <Divider />
      {fileData && config ? (
        <div className="main-dynamic-thumbnail">
          <div>
            <form
              className="BlingsContainer form-container"
              onSubmit={handleSubmit}
            >
              <br />
              <div className="container">
                <div className="previewContainer">
                  <div className="uplaodImgContainer">
                    <div>
                      <Upload
                        {...uploadProps}
                        showUploadList={false}
                        className="thumb-upload"
                      >
                        <Button
                          icon={<UploadOutlined />}
                          style={{ height: "45px" }}
                        >
                          Upload a new image
                        </Button>
                      </Upload>
                    </div>
                  </div>
                  <div className="previewImgContainer">
                    {DynamicThumbStatus === AsyncOpState.Saving ? (
                      <>
                        {image ? (
                          <img
                            className="previewImg blurred"
                            src={image || ""}
                          />
                        ) : (
                          <div style={{ height: "15rem" }}></div>
                        )}
                        <div className="overlay-spinner spinner">
                          <img
                            src={LoadingSpinner}
                            className="Rotate"
                            alt="Loading Icon"
                          />
                        </div>
                      </>
                    ) : (
                      <img className="previewImg" src={image || ""} />
                    )}
                  </div>
                  <div className="preview-param-box">
                    <p className="previewText">Preview</p>
                    <div className="paramBox">
                      <p className="param1">Param 1</p>
                      <Input
                        className="param"
                        defaultValue={
                          config.previewParams[0] !== ""
                            ? config.previewParams[0]
                            : undefined
                        }
                        placeholder="Param1"
                        onChange={(e) => handleInputChange(0, e)}
                      />
                    </div>
                    <div className="paramBox">
                      <p className="param2">Param 2</p>
                      <Input
                        className="param"
                        defaultValue={
                          config.previewParams[1] !== ""
                            ? config.previewParams[1]
                            : undefined
                        }
                        placeholder="Param2"
                        onChange={(e) => handleInputChange(1, e)}
                      />
                    </div>
                    <Popover
                      className="tooltip"
                      placement="right"
                      overlayInnerStyle={{ width: "250px" }}
                      style={{ zIndex: "0" }}
                      getPopupContainer={(trigger) =>
                        trigger?.parentElement || document.body
                      }
                      content={
                        <ul className="extra-info-popover">
                          <li>
                            Update these parameter values to see a live preview.
                          </li>
                          <li>
                            These parameters can be linked to CRM tokens, such
                            as a recipient's first name
                          </li>
                        </ul>
                      }
                    >
                      <div>
                        <QuestionCircleOutlined
                          style={{
                            color: "var(--blings_icon_gray)",
                            cursor: "pointer",
                          }}
                        />
                      </div>
                    </Popover>
                  </div>
                </div>
                <div className="textContainer">
                  <InputWithTags
                    previewText="This is the top text!"
                    displayText="Top Text"
                    previewTextArr={config.texts[0].textTemplate || []}
                    config={config}
                    setConfig={setConfig}
                    textIndex={0}
                  />
                  <div className="play-button-container">
                    <p className="playbutton">Play Button</p>
                    <ThumbnailPlayButton
                      config={config}
                      setConfig={setConfig}
                    />
                  </div>
                  <InputWithTags
                    previewText="This is the bottom text hooray!"
                    displayText="Bottom Text"
                    previewTextArr={config.texts[1].textTemplate}
                    config={config}
                    setConfig={setConfig}
                    textIndex={1}
                  />
                </div>
              </div>
              <div className="save-btn">
                <BlingsBtn
                  className="BlingsButton"
                  htmlType="submit"
                  opState={saveState}
                  btnTexts={FEEDBACK_OPTIONS}
                />
              </div>
            </form>
          </div>
          <div className="link-div">
            <Select
              size={"large"}
              className="crm-select"
              onChange={(e) => setSelectedCrmIndex(e)}
              value={selectedCrmIndex}
              placeholder={"Select Crm"}
              options={crms.map((crm, i) => ({
                value: i,
                label: (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "250px",
                      alignItems: "center",
                    }}
                  >
                    {crm.name}
                    <img
                      alt={"Crm logo"}
                      src={crm.img}
                      height="26px"
                      width="auto"
                    />
                  </div>
                ),
              }))}
            />

            <div className="sub-text">
              This link generates a dynamic thumbnail based on your parameters.
              To personalize the thumbnail for each email recipient, replace
              Param1 and Param2 with the respective CRM tokens.
            </div>
            <Tabs items={items} />
          </div>
        </div>
      ) : (
        <div>
          <Upload
            {...uploadProps}
            showUploadList={false}
            className="thumb-upload-new"
          >
            <Button icon={<UploadOutlined />} style={{ height: "45px" }}>
              Upload a base image
            </Button>
          </Upload>
        </div>
      )}
    </div>
  );
});
