import { generateClient, get, post } from "aws-amplify/api";
import { Instance, types } from "mobx-state-tree";
import {
  NEW_REST_API,
  REST_API_V2__GET_USE_CASES,
  REST_API_V2__INSTANTIATE_USE_CASE,
} from "../../consts";
import {
  ModelUseCaseConnection,
  ModelUseCaseTagConnection,
  UseCase,
  UseCaseTag,
} from "../../API";
import { listUseCases, listUseCaseTags } from "../../graphql/queries";

export const UseCasesModel = types
  .model("UseCasesModel", {
    tags: types.optional(types.array(types.frozen<UseCaseTag>()), []),
    useCases: types.optional(types.array(types.frozen<UseCase>()), []),
  })
  .actions((self) => ({
    afterCreate: async () => {
      try {
        const client = generateClient();
        let nextToken: string | null | undefined = null;
        const allTags: Array<UseCaseTag> = [];
        do {
          const tagsResponse = (await client.graphql({
            query: listUseCaseTags,
            variables: { nextToken },
          })) as {
            data: { listUseCaseTags: ModelUseCaseTagConnection };
          };
          allTags.push(
            ...(tagsResponse.data.listUseCaseTags.items.filter(
              (t) => t
            ) as Array<UseCaseTag>)
          );
          nextToken = tagsResponse.data.listUseCaseTags.nextToken;
        } while (nextToken);
        self.tags.replace(allTags);
        nextToken = null;
        const allUseCases: Array<UseCase> = [];
        do {
          const useCasesResponse = (await client.graphql({
            query: listUseCases,
            variables: { nextToken },
          })) as {
            data: { listUseCases: ModelUseCaseConnection };
          };

          allUseCases.push(
            ...(useCasesResponse.data.listUseCases.items.filter(
              (t) => t
            ) as Array<UseCase>)
          );
          nextToken = useCasesResponse.data.listUseCases.nextToken;
        } while (nextToken);

        self.useCases.replace(allUseCases);
      } catch (e) {
        console.error(e);
      }
    },
    getUseCasesFromTags: (tags: Array<string>) => {
      if (tags.length === 0) return self.useCases;
      const useCaseTags = self.tags.filter((tag) => tags.includes(tag.tag));
      const useCaseIds = Array.from(
        new Set(useCaseTags.flatMap((tag) => tag.useCaseIds))
      );
      return self.useCases.filter((useCases) =>
        useCaseIds.includes(useCases.id)
      );
    },
    instantiateUseCase: async (accountId: string, useCaseId: string) => {
      await post({
        apiName: NEW_REST_API,
        path: REST_API_V2__INSTANTIATE_USE_CASE(accountId, useCaseId),
      });
    },
  }));
