import { toast } from "react-toastify";
import { getENV } from "../../config";
import { rootStore } from "../../stores/Root";
import {
  InstantNotification,
  NotificationType,
} from "../../types/Notification";
import { Instance } from "mobx-state-tree";
import { ExportMp4Icon } from "../../assets/Icons";
import { Tooltip } from "antd";

import "./Icons.scss";
interface Props {
  projectId: string;
  scenes: string[];
  data: any;
  playerVersion?: string | null;
}

export function ExportToMP4({ projectId, scenes, data, playerVersion }: Props) {
  return (
    <Tooltip placement="bottom" title={"Export Preview as MP4"}>
      <a
        style={{
          display: "flex",
          alignItems: "center",
        }}
        target="_blank"
        rel="noopener noreferrer"
        onClick={() => {
          CallServerRender(projectId, scenes, data, playerVersion);
        }}
      >
        <div className="command-bar-icon">
          <ExportMp4Icon />
        </div>
      </a>
    </Tooltip>
  );
}

async function CallServerRender(
  projectId: string,
  scenes: string[],
  data: any,
  playerVersionToUse?: string | null
) {
  /* Format that the server render accepts
    { 
        "data": { "firstName": "Carlos"},
        "scenes": ["Main"],
        "projectId": "Pilot_SDR",
        "env": "dev",
        "quality": "1",
        "outputFileName": "teste"
        "playerVersion": "3-1-5"
    } 
    */
  const serverRenderData: any = {
    data,
    scenes,
    projectId: projectId,
    env: getENV(),
    quality: "3", // Medium quality
  };
  if (playerVersionToUse)
    serverRenderData["playerVersion"] = playerVersionToUse.replace(
      /[\.]/g,
      "-"
    );

  const authSession = rootStore.userStore.session;
  const userCredentials = authSession.accessToken.toString();

  toast(`MP4 rendering requested!`, {
    position: "bottom-left",
    autoClose: 2500,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });

  const url =
    getENV() === "dev" //"http://127.0.0.1:3000/start-create" : "https://mp4.blings.io/start-create"
      ? //"http://localhost:3000/start-create" : ""
        "https://cors.blings.io/http://54.170.15.161:3000/start-create"
      : "https://mp4.blings.io/start-create";
  fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + userCredentials,
    },
    body: JSON.stringify(serverRenderData),
  })
    .then((response) => {
      // Listen to subscriptions from the server render
      // creates a toast notification when receive a instant notification
      // and creates a toast notification when receive a normal notification
      try {
        rootStore.notificationStore.addNotificationSubscription({
          id: "render-callback-normal" + projectId,
          type: [NotificationType.Render],
          subType: ["rendering"],
          executeOnlyOnce: true, // shall only receive 1 normal notification, the one that says the render is done
          sendType: "Normal",
          callback: (
            notification: Instance<typeof Notification> | InstantNotification
          ) => {
            //console.log("Normal notification", notification);
            toast(
              `Your render has started. We will notify you when your file is ready.`,
              {
                position: "bottom-left",
                autoClose: 2500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              }
            );
          },
        });
      } catch (error) {
        console.error(error);
      }
    })
    .catch((error) => {
      toast(`Error when trying to render. Please try again later...`, {
        position: "bottom-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    });
}
