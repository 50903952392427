import { useState, useRef, useEffect } from "react";
import _ from "lodash";
import { cleanEmptyStringInValue } from "./object.helpers";
import useDebounce from "./DebounceHook";

export const useBlingsDynamicForm = (project: any, dataExamples: any, schema: any, dataPointsFromManual: any, dataPointsFromCSV: any, initialSelectedDataPointId?: string, initialSavedFormData?: any) => {
  const [currentDataPointsFromManual, setCurrentDataPointsFromManual] = useState<any>(dataPointsFromManual);
  const [currentDataPointsFromCSV, setCurrentDataPointsFromCSV] = useState<any>(dataPointsFromCSV);
  const [selectedDataPointId, setSelectedDataPointId] = useState<string | undefined>(initialSelectedDataPointId || undefined);
  const [lastFormChange, setLastFormChange] = useState<({ [key: string]: string | any }) | null>(initialSavedFormData ? cleanEmptyStringInValue(initialSavedFormData) : null);
  const [currentFormData, setCurrentFormData] = useState<any>(initialSavedFormData ? initialSavedFormData : dataExamples ? cleanEmptyStringInValue(dataExamples) : undefined);
  const [savedFormData, setSavedFormData] = useState<any>(initialSavedFormData ? cleanEmptyStringInValue(initialSavedFormData) : undefined);
  const [formHasChanged, setFormHasChanged] = useState<boolean>(initialSavedFormData ? !_.isEqual(initialSavedFormData, dataExamples) : false);
  const [recordChanged, setChangedFromRecordData] = useState<boolean>(false);

  const debouncedValue = useDebounce(lastFormChange, 1400); // Debounced form change

  const submit = useRef<() => void>(() => {
    return;
  });

  // Function to fill data from the selected record
  // https://stackoverflow.com/questions/19098797/fastest-way-to-flatten-un-flatten-nested-json-objects
  const flattenKeys = (data: any) => {
    const result = {} as any;
    function recurse(cur: any, prop: any) {
      if (Object(cur) !== cur) {
        result[prop] = cur;
      } else if (Array.isArray(cur)) {
        const l = cur.length;
        for (let i = 0; i < l; i++) recurse(cur[i], prop + "[" + i + "]");
        if (l === 0) result[prop] = [];
      } else {
        let isEmpty = true;
        for (const p in cur) {
          isEmpty = false;
          recurse(cur[p], prop ? prop + "." + p : p);
        }
        if (isEmpty && prop) result[prop] = {};
      }
    }
    recurse(data, "");
    return Object.keys(result);
  };

  const fillDataFromRecord = (dataId?: string) => {
    const record = dataId
    ? currentDataPointsFromManual?.[dataId] ?? currentDataPointsFromCSV?.[dataId] ?? dataExamples
    : dataExamples;

    const paths = flattenKeys(record);
    paths.forEach((path) => {
      const schemePath = path.split(".").join(".properties.");
      const type = _.get(schema.properties, `${schemePath}.type`);
      if (!type) return;
      const strValue = _.get(record, path);
      let value: string | number | boolean = strValue;

      switch (type) {
        case "integer":
        case "number":
          value = Number(strValue);
          break;
        case "boolean":
          value = strValue === true || strValue === "true";
          break;
        default:
          break;
      }
      _.set(record, path, value);
    });
    setLastFormChange(cleanEmptyStringInValue(record));
    setCurrentFormData(cleanEmptyStringInValue(record));
    setSavedFormData(cleanEmptyStringInValue(record));
    setChangedFromRecordData(false);
  };

  useEffect(() => {
    submit.current();
  }, [debouncedValue]);

  return {
    selectedDataPointId,
    setSelectedDataPointId,
    lastFormChange,
    currentFormData,
    setLastFormChange,
    setCurrentFormData,
    savedFormData,
    setSavedFormData,
    formHasChanged,
    setFormHasChanged,
    recordChanged,
    setChangedFromRecordData,
    debouncedValue,
    submit,
    fillDataFromRecord,
    setCurrentDataPointsFromCSV,
    setCurrentDataPointsFromManual,
    flattenKeys,
  };
};
