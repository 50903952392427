import MuterSingleton from "../../utils/mute";
import { useState } from "react";
import { VolumeUp, Mute } from "../../icons-and-animations/icons";
import { Tooltip } from "antd";

import "./GlobalMuteButton.scss";
import "./Icons.scss";

export const GlobalMuteButton = ({ color }: { color?: string }) => {
  const [muteState, setMuteState] = useState(
    MuterSingleton.instance.isMuting()
  );
  return (
    <div className="mute-container">
      <div className="mute-wrapper">
        <Tooltip
          placement="bottom"
          title={muteState ? "Global Unmute" : "Global Mute"}
        >
          <span
            className="btn-wrapper"
            onClick={() => {
              MuterSingleton.instance.toggleMute();
              setMuteState(MuterSingleton.instance.isMuting());
            }}
          >
            <div className="command-bar-icon">
              {muteState ? <Mute color={color} /> : <VolumeUp color={color} />}
            </div>
          </span>
        </Tooltip>
      </div>
    </div>
  );
};

export default GlobalMuteButton;
