import * as React from "react";
import { IProjectModel } from "../../stores/project/projectModel";
import { useRef, useState, useEffect, useCallback } from "react";
import { Player } from "@blings/blings-player";
import PlayerManager from "../../utils/playerManager";
import { getENV } from "../../config";
import { observer } from "mobx-react-lite";
import LoadingSpinner from "../../assets/Ellipse 7.png";
import {
  IFlowScene,
  ISdkSettings,
} from "@blings/blings-player/lib/src/SDK/sdk.api";
import useDebounce from "../../helpers/DebounceHook";
import { FrameIndicator } from "./FrameIndicator";
import GlobalMuteButton from "../../components/Icons/GlobalMuteButton";
import { usePlayerContext } from "../../contexts/playerContext";
import { PreviewDemo } from "../../components/Icons/PreviewDemo";
import { CopyPreviewURL } from "../../components/Icons/CopyPreviewURL";
import { ExportToMP4 } from "../../components/Icons/ExportToMP4";

import "./PlayerComponent.scss";

type Props = {
  project: IProjectModel;
  data: string;
  selectedDataID?: string;
  settings?: Partial<ISdkSettings>;
  recordChanged?: boolean;
  frameIndicator?: boolean;
  renderMp4?: boolean;
  showExtraOptions?:
    | boolean
    | {
        exportMp4: boolean;
        copyUrl: boolean;
        watchPreview: boolean;
        sceneSelector: boolean;
        globalMute: boolean;
      };
  selectedScenes?: string[];
};

type ContainerProps = {
  commandBarOptions: {
    showExportMp4?: boolean;
    showCopyUrl?: boolean;
    showWatchPreview?: boolean;
    showSceneSelector?: boolean;
    showGlobalMute?: boolean;
    showFrameIndicator?: boolean;
    maxWidth?: string;
    maxHeight?: string;
  };
  emptyPlayer?: JSX.Element;
  playerOptions: PlayerProps;
};

type PlayerProps = {
  useScenesFromContext?: boolean;
  playerVersionToUse?: string | null;
  projectID: string;
  selectedScenes?: IFlowScene[];
  data?: string;
  selectedDataID?: string;
  settings?: Partial<ISdkSettings>;
  vertical?: boolean;
  callbackIsLoading?: React.Dispatch<React.SetStateAction<boolean>>;
  isLoading?: boolean;
  setCurrentFrame?: React.Dispatch<React.SetStateAction<string>>;
  setTotalFrames?: React.Dispatch<React.SetStateAction<string>>;
};

interface LinkProps extends Props {
  scenes?: IFlowScene[];
  selectedDataID?: string;
}

interface InnerProps extends Props {
  // project: IProjectModel;
  // data: string;
  scenes?: IFlowScene[];
  vertical: boolean;
  settings?: Partial<ISdkSettings>;
  frameIndicator?: boolean;
  playerVersionToUse: string | undefined;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  isLoading: boolean;
}

export const PlayerComponent = observer((props: ContainerProps) => {
  // Props
  const { commandBarOptions, playerOptions, emptyPlayer } = props;
  const {
    showCopyUrl,
    showExportMp4,
    showFrameIndicator,
    showGlobalMute,
    showSceneSelector,
    showWatchPreview,
  } = commandBarOptions;
  const {
    playerVersionToUse,
    selectedScenes,
    data,
    selectedDataID,
    settings,
    projectID,
    useScenesFromContext,
  } = playerOptions;

  // Context information
  const selectedScenesFromContext = usePlayerContext().selectedScenes;

  // States
  const [loading, setLoading] = useState<boolean>(true);
  const [scenes, setScenes] = useState<IFlowScene[]>(() => {
    if (
      useScenesFromContext &&
      selectedScenesFromContext &&
      selectedScenesFromContext.length > 0
    )
      return selectedScenesFromContext;
    if (selectedScenes && selectedScenes.length > 0) return selectedScenes;
    return [];
  });
  const [currentFrame, setCurrentFrame] = useState<string>("");
  const [totalFrames, setTotalFrames] = useState<string>("");

  // useEffects
  useEffect(() => {
    return () => {
      PlayerManager.get().removeCurrentPlayer();
    };
  }, []);

  useEffect(() => {
    if (
      selectedScenesFromContext &&
      selectedScenesFromContext.length > 0 &&
      useScenesFromContext
    ) {
      setScenes(selectedScenesFromContext);
    } else if (selectedScenes && selectedScenes.length > 0) {
      setScenes(selectedScenes);
    } else {
      setScenes([]);
    }
  }, [selectedScenes, selectedScenesFromContext, useScenesFromContext]);

  // Debounces
  const debouncedSettings = useDebounce<Partial<ISdkSettings> | undefined>(
    settings,
    1000
  );
  const debouncedScenes = useDebounce<any>(scenes, 1000);

  // Extra logic
  const width = debouncedScenes[0]?.jsonData?.w;
  const height = debouncedScenes[0]?.jsonData?.h;
  const isVertical = height > width;

  return (
    <div className="player-wrapper">
      {scenes.length ? (
        <div
          className={
            (loading ? "loading-placeholder" : "") + " player-container"
          }
        >
          <_Player
            callbackIsLoading={setLoading}
            isLoading={loading}
            vertical={isVertical}
            data={data}
            playerVersionToUse={playerVersionToUse}
            selectedDataID={selectedDataID}
            selectedScenes={debouncedScenes}
            settings={debouncedSettings}
            projectID={projectID}
            setCurrentFrame={setCurrentFrame}
            setTotalFrames={setTotalFrames}
          />

          {loading ? (
            <div className="player-spinner spinner" style={{ marginTop: 0 }}>
              <img src={LoadingSpinner} className="Rotate" alt="Loading Icon" />
            </div>
          ) : (
            <div className="command-bar">
              <div className="action-btn">
                {showGlobalMute && <GlobalMuteButton />}

                {showWatchPreview && (
                  <PreviewDemo
                    data={data}
                    playerVersion={playerVersionToUse}
                    projectId={projectID}
                    scenes={debouncedScenes}
                    selectedDataID={selectedDataID}
                  />
                )}

                {showCopyUrl && (
                  <CopyPreviewURL
                    data={data}
                    playerVersion={playerVersionToUse}
                    projectId={projectID}
                    scenes={debouncedScenes}
                    selectedDataID={selectedDataID}
                  />
                )}

                {showExportMp4 && (
                  <ExportToMP4
                    data={data}
                    playerVersion={playerVersionToUse}
                    projectId={projectID}
                    scenes={debouncedScenes}
                  />
                )}
              </div>
              {showFrameIndicator && (
                <FrameIndicator
                  currentFrame={currentFrame}
                  totalFrames={totalFrames}
                />
              )}
            </div>
          )}
        </div>
      ) : (
        <div className="empty-player">
          {emptyPlayer ? (
            emptyPlayer
          ) : (
            <>
              <p>Add Scenes or Flows</p>
              <p>to preview them here</p>
            </>
          )}
        </div>
      )}
    </div>
  );
});

/**
 * Component to render the Blings Player using dynamic data
 * @param props - All the data in one object. This data is:
 * - project: IProjectModel; - The project model containing the project ID.
 * - data: string; - The data of the dynamic data for the player.
 * - scenes?: string[]; - The scenes for the video that the player must load.
 * - settings?: Partial<ISdkSettings>; - The settings for the player.
 * @returns {React.FC<LinkProps>} - A div component where the player will be rendered.
 */
const _Player = observer((props: PlayerProps) => {
  // Props
  const {
    playerVersionToUse,
    projectID,
    selectedScenes,
    data,
    selectedDataID,
    settings,
    vertical,
    callbackIsLoading,
    isLoading,
    setCurrentFrame,
    setTotalFrames,
  } = props;

  // This is the container reference to where the player will be rendered
  // This will reference the div element that is always rendered
  // And the player SDK will be rendered inside this div
  const containerRef = useRef<HTMLDivElement>(null);

  // This flag will be used to control if the player can be created or not
  // If a player is already created or dont exists, this flag will be set to true
  // If a player is being created, this flag will be set to false
  const [canCreatePlayer, setCanCreatePlayer] = useState(true);

  // This will be used to check if the player needs a new configuration
  // If this is different from the last configuration, the player will be recreated
  const [lastConfig, setLastConfig] = useState<string>("");
  const [actualConfig, setActualConfig] = useState<string>("");

  // This function will be used to create the player with the actual configuration
  const CreatePlayer = useCallback(() => {
    if (!canCreatePlayer) {
      return;
    }
    setCanCreatePlayer(false);

    // Check if a player already exists
    if ((window as any).p) {
      // If a player already exists, we want to destroy it
      try {
        const lastIndex = (window as any).p.index;
        (window as any).p.stop();
        (window as any).p.destroy();
        // Clean old players if exists
        if ((window as any).BlingsPlayer)
          Object.keys((window as any).BlingsPlayer.players).forEach((key) => {
            if (key < lastIndex) {
              (window as any).BlingsPlayer.players[key].destroy();
            }
          });
      } catch (e) {
        console.error("err destroying player", e);
      }
    }
    // Create the object that will be used to create the player
    const { projectID, data, settings, selectedScenes }: PlayerProps =
      JSON.parse(actualConfig);
    // Create the player
    if (containerRef.current && selectedScenes?.length) {
      containerRef.current.innerHTML = "";
      // Create the player
      new PlayerManager();

      // Get the major version of the player
      let majorVersion = parseInt(playerVersionToUse?.split(".")[0] as string);
      if (Number.isNaN(majorVersion)) majorVersion = -1;
      if (callbackIsLoading) callbackIsLoading(true);
      PlayerManager.get()
        .createPlayer(
          {
            project: {
              env: getENV(),
              projectId: projectID,
            },
            data,
            settings: { container: containerRef.current, ...settings },
            scenes: selectedScenes,
          },
          {
            playerMajorVersion: majorVersion,
          }
        )
        .then((p: Player) => {
          if (callbackIsLoading) callbackIsLoading(false);
          // Store the player on window, on a state and set the flag to allow creation of new players
          (window as any).p = p;
          setCanCreatePlayer(true);
          // Set the last configuration used to the current configuration
          // The last config defines what the player was created with
          setLastConfig(actualConfig);
          if (setCurrentFrame)
            setCurrentFrame(p.animation.currentFrame.toString());
          if (setTotalFrames)
            setTotalFrames(p.animation.totalFrames.toString());
          p.EE.on("onFrame", () => {
            if (setCurrentFrame)
              setCurrentFrame(p.animation.currentFrame.toString());
          });
        });
    } else {
      setCanCreatePlayer(true);
      setLastConfig(actualConfig);
    }
  }, [actualConfig, callbackIsLoading, playerVersionToUse]);

  // This will be used to control if the player is being created or not
  useEffect(() => {
    // Store the actual config on a state for access for later verification
    setActualConfig(
      JSON.stringify({
        projectID,
        selectedScenes,
        data,
        settings,
        playerVersionToUse,
      })
    );
  }, [projectID, selectedScenes, data, settings, playerVersionToUse]);

  // Try to create the player always that the actual config is changed
  useEffect(() => {
    setLastConfig(actualConfig);
    if (actualConfig !== "") CreatePlayer();
  }, [actualConfig]);

  // This will be used to control if the player needs a new configuration, so a new player needs to be created
  useEffect(() => {
    if (canCreatePlayer) {
      if (actualConfig !== lastConfig) {
        CreatePlayer();
      }
    }
  }, [canCreatePlayer, containerRef]);
  return (
    <div className="player">
      <div ref={containerRef} id={Math.random().toString(36).substring(7)} />
    </div>
  );
});
