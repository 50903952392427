import { observer } from "mobx-react-lite";
import { useMst } from "../../../stores/Root";
import { useEffect, useState } from "react";
import { Typography } from "antd";
import { ExperimentOptimizationTechnique } from "../../../API";
import ExperimentPanel from "../../../components/AI/Optimization/VariantPanel/ExperimentPanel";
import EnableOptimizationPanel from "../../../components/AI/Optimization/OptimizationPanel/EnableOptimizationPanel";
import "./AIOptimization.scss";
import OptimizationPanel from "../../../components/AI/Optimization/OptimizationPanel/OptimizationPanel";
import { StarsIcon } from "../../../assets/Icons";
import { BlingsCard } from "../../../components/BlingsCard/BlingsCard";

type Props = {
  projectId: string;
};

function AIOptimization(props: Props) {
  // State declarations
  const {
    accountCan,
    selectedExperimentOptimizationTechnique,
    projectAccountId,
    loadStatus,
  } = useMst((store) => {
    return {
      loadStatus: store.userStore.loadStatus,
      accountCan: store.userStore.accountFromId(
        store.projectsStore.selectedProject?.account?.id
      )?.accountCan,
      selectedExperimentOptimizationTechnique:
        store.projectsStore.selectedProject?.experiments
          ?.selectedExperimentOptimizationTechnique,
      projectAccountId: store.projectsStore.selectedProject?.account?.id,
    };
  });
  const [accountCanAccess, setAccountCanAccess] = useState(
    accountCan(projectAccountId as string, "aiOptimization")
  );
  const [_selectedSuccessFactor, setSelectedSuccessFactor] =
    useState<ExperimentOptimizationTechnique>(
      selectedExperimentOptimizationTechnique
    );
  const [
    shouldShowOptimizationSelectionPanel,
    setShouldShowOptimizationSelectionPanel,
  ] = useState(false);

  // useEffect declarations
  useEffect(() => {
    setSelectedSuccessFactor(
      selectedExperimentOptimizationTechnique ||
        ExperimentOptimizationTechnique.DISABLED
    );
  }, [selectedExperimentOptimizationTechnique]);

  useEffect(() => {
    setAccountCanAccess(
      accountCan(projectAccountId as string, "aiOptimization")
    );
  }, [loadStatus]);

  return (
    <div className="ai-page">
      {!accountCanAccess ? (
        <div className="ai-optimization-not-allowed">
          <StarsIcon />
          <div className="ai-optimization-not-allowed-content">
            <Typography.Text>AI is disabled for this account</Typography.Text>
            <Typography.Text>
              Contact sales for more information
            </Typography.Text>
          </div>
        </div>
      ) : (
        <>
          {_selectedSuccessFactor !==
            ExperimentOptimizationTechnique.DISABLED &&
          !shouldShowOptimizationSelectionPanel ? (
            <BlingsCard className="ai-optimization-optimization-selection-card">
              <OptimizationPanel
                setShouldShowOptimizationSelectionPanel={
                  setShouldShowOptimizationSelectionPanel
                }
              />
            </BlingsCard>
          ) : null}
          <BlingsCard className="ai-optimization-container">
            {_selectedSuccessFactor !==
              ExperimentOptimizationTechnique.DISABLED &&
            !shouldShowOptimizationSelectionPanel ? (
              <ExperimentPanel />
            ) : (
              <EnableOptimizationPanel
                setShouldShowOptimizationSelectionPanel={
                  setShouldShowOptimizationSelectionPanel
                }
              />
            )}
          </BlingsCard>
        </>
      )}
    </div>
  );
}

export default observer(AIOptimization);
