import { observer } from "mobx-react-lite";
import { DynamicForm } from "@blings/dynamic-form";
import BlingsPlatformTheme from "../../config/antdTheme";
import { cleanEmptyStringInValue } from "../../helpers/object.helpers";
import { useMst } from "../../stores/Root";
import "./BlingsDynamicForm.scss";

interface Props {
  readOnly?: boolean;
  editable?: boolean;
  dynamicFormProps: {
    schema: object;
    currentFormData: object;
    setLastFormChange: (formData: object) => void;
    setCurrentFormData: (formData: object) => void;
    setFormHasChanged: (hasChanged: boolean) => void;
    setChangedFromRecordData: (hasChanged: boolean) => void;
    submit: React.MutableRefObject<() => void>;
  };
  className?: string;
  setIsModalOpen: any;
  dataPointSelectorProps: {
    selectedDataPointId: string | undefined;
    setSelectedDataPointId: any;
    fillDataFromRecord: any;
  };
  target: string;
}

export const BlingsDynamicForm = observer(
  ({
    readOnly,
    editable,
    dynamicFormProps,
    className,
    setIsModalOpen,
    dataPointSelectorProps,
    target,
  }: Props) => {
    const { uploadAssetToProject, getAssetsUploadStatus, uploadAssetStatus } =
      useMst((state) => ({
        uploadAssetToProject: state.liveControlStore.uploadAssetToProject,
        getAssetsUploadStatus: state.liveControlStore.getAssetsUploadStatus,
        uploadAssetStatus: state.liveControlStore.uploadAssetStatus,
      }));

    const {
      schema,
      currentFormData,
      setLastFormChange,
      setCurrentFormData,
      setChangedFromRecordData,
      submit,
    } = dynamicFormProps;

    return (
      <div
        className={"schema-view " + className}
        onDoubleClick={() => {
          if (target !== "Default Version") {
            setIsModalOpen({ modalOpen: true, mode: "edit" });
          } else {
            dataPointSelectorProps.setSelectedDataPointId(undefined);
            dataPointSelectorProps.fillDataFromRecord(undefined);
            setIsModalOpen({ modalOpen: true, mode: "create" });
          }
        }}
      >
        <DynamicForm
          // @ts-ignore
          themeConfig={BlingsPlatformTheme}
          schema={schema}
          formData={currentFormData}
          editable={editable || false}
          readable={readOnly || false}
          onChange={(formData: any) => {
            setLastFormChange(cleanEmptyStringInValue(formData));
          }}
          saveData={(d) => {
            setCurrentFormData(cleanEmptyStringInValue(d));
            setChangedFromRecordData(true);
          }}
          submit={submit}
          uploadAssetToProject={uploadAssetToProject as any} // TODO: FIX THIS
          getAssetsUploadStatus={getAssetsUploadStatus}
        />
      </div>
    );
  }
);
