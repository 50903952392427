import { toast } from "react-toastify";
import { EXAMPLE_PLAYER_LINK } from "../../consts";
import { getENV } from "../../config";
import { CopyUrl } from "../../assets/Icons";
import { Tooltip } from "antd";

import "./Icons.scss";

interface Props {
    projectId: string;
    playerVersion?: string | null;
    scenes: string[];
    selectedDataID?: string;
    data: any;
    recordChanged?: boolean;
}

export function CopyPreviewURL({
    projectId,
    scenes,
    data,
    playerVersion,
    recordChanged,
    selectedDataID
}: Props) {
    let url = `${EXAMPLE_PLAYER_LINK}?env=${getENV()}&p=${projectId}`;
    if (scenes) {
        url += `&scenes=${encodeURIComponent(scenes.join())}`;
    }
    if (selectedDataID) {
        url += `&id=${selectedDataID}`;
    }

    if (data && (!selectedDataID || recordChanged)) {
        url += `&data=${encodeURIComponent(JSON.stringify(data))}`;
    }

    return (
        <Tooltip
            placement="bottom"
            title={"Copy Preview URL"}
        >
            <a
                style={{
                    display: "flex",
                    alignItems: "center",
                }}
                onClick={() => {
                    const currentVersion = playerVersion;
                    // Do nothing if the player has the latest version
                    if (currentVersion === "latest") {
                    }
                    // If there is no player version, use the one that the project has specified
                    else if (currentVersion === "WARN_NO_PLAYER_VERSION") {
                        console.warn(
                            "No player version specified, using project version"
                        );
                        console.warn("Project version: ", currentVersion);
                        if (url.includes("&v="))
                            url = url.replace(
                                /&v=.*/,
                                `&v=${(currentVersion as string).replaceAll(
                                    ".",
                                    "-"
                                )}`
                            );
                        else
                            url += `&v=${(currentVersion as string).replaceAll(
                                ".",
                                "-"
                            )}`;
                    }
                    // If there is a player version, use that
                    else {
                        if (url.includes("&v="))
                            url = url.replace(/&v=.*/, `&v=${currentVersion}`);
                        else url += `&v=${currentVersion}`;
                    }
                    toast(`Copied to clipboard`, {
                        position: "bottom-left",
                        autoClose: 2500,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                    navigator.clipboard.writeText(url);
                }}
            >
                <div className="command-bar-icon">
                    <CopyUrl />
                </div>
            </a>
        </Tooltip>
    )
}