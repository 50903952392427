import { IDataPointModel, IProjectBaseModel } from "./projectModel";
import { put, get } from "aws-amplify/api";
import {
  NEW_REST_API,
  NEW_REST_API__V2__DATA_RECORDS_PATH,
} from "../../consts";
import { AsyncOpState } from "../../types/enums/async-op-states";
import { INITIAL_ASYNC_OP_STATE } from "../../types/mst/async-op-state";
// type x = Pick<IProjectModel, 'model'>

export const DATA_ID_SCHEMA_PROP = "data_id";

export const createDataPointActions = (self: IProjectBaseModel) => ({
  updateNewDPFormData(data: any) {
    self.newDPFormData = data;
  },
  async loadExistingDPs() {
    const res = await get({
      apiName: NEW_REST_API,
      path: NEW_REST_API__V2__DATA_RECORDS_PATH(self.id as string),
    }).response;
    const data = await res.body.json();
    self.setCreatedDPList(data as any);
  },

  async putDataPoint(newDp: any, dataId: string): Promise<IDataPointModel> {
    const fullRecord = {
      dataId,
      record: newDp,
    };

    try {
      const res = await put({
        apiName: NEW_REST_API,
        path: NEW_REST_API__V2__DATA_RECORDS_PATH(self.id as string),
        options: {
          body: fullRecord,
        },
      });
      const response = (await (await res.response).body.json()) as any;
      const data = response?.data as unknown as IDataPointModel;
      return data;
    } catch (e) {
      console.error("err", e);
      throw new Error("Failed to add data point");
    }
  },

  async createDataPoint(newDp: any, dataId: string) {
    this.changeCreateDPStatus(AsyncOpState.Saving);
    try {
      // Add the just created data point to the project
      const newDataPoint = await this.putDataPoint(newDp, dataId);
      self.addNewDataPointToProject(newDataPoint);

      this.createDPSuccess();
    } catch (e: any) {
      this.changeCreateDPStatus(AsyncOpState.Error);
      setTimeout(() => {
        this.changeCreateDPStatus(INITIAL_ASYNC_OP_STATE);
      }, 3000);
      throw new Error(e.message); // for view to show the latest error
    }
  },

  createDPSuccess(/*settingsJsonSchemaStr: string, settings: string*/) {
    this.changeCreateDPStatus(AsyncOpState.Success);
    setTimeout(() => {
      this.changeCreateDPStatus(INITIAL_ASYNC_OP_STATE);
      // this.updateNewDPFormData({});
    }, 2000);
  },
  changeCreateDPStatus(newStatus: AsyncOpState) {
    self.createNewDPStatus = newStatus;
  },
});
