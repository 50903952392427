import { PlayerSettingsUI } from "../PlayerSettings/PlayerSettings";
import { UpdateProject } from "./UpdateProject";
import { UpdateProjectSchemas } from "./ProjectSchemas";
import { IProjectModel } from "../../stores/project/projectModel";
import ProjectDataSettings from "./ProjectDataSettings";
import { Tabs, Divider } from "antd";
import "./ProjectSettings.scss";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { PATHS, PROJECT_PATHS, toPath } from "../../PATHS";
import { useEffect } from "react";

type Props = {
  project: IProjectModel;
};
export const ProjectSettings = ({ project }: Props) => {
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname.endsWith(PROJECT_PATHS.settings)) {
      handleTabChange("projectSettings");
    }
  }, [location.pathname, navigate, params?.id]);

  const handleTabChange = (key: string) => {
    navigate(
      toPath(PATHS.project, params?.id || "", PROJECT_PATHS.settings, `${key}`)
    );
  };

  const items = [
    {
      label: <span>Project</span>,
      key: "projectSettings",
      children: (
        <div
          style={{
            height: "calc(100vh - 225px)",
            overflow: "hidden",
          }}
        >
          <Divider className="tab-divider" />
          <div className="project-conetet">
            <UpdateProject project={project} />
            {project.allowDataConnect && <ProjectDataSettings />}
          </div>
        </div>
      ),
    },
    {
      label: <span>Player</span>,
      key: "playerSettings",
      children: (
        <>
          <Divider className="tab-divider" />
          <PlayerSettingsUI project={project} />
        </>
      ),
    },
    {
      label: <span>Dynamic Data</span>,
      key: "dataSchema",
      children: (
        <>
          <Divider className="tab-divider" />
          <UpdateProjectSchemas project={project} />
        </>
      ),
    },
  ];

  return (
    <div className="project-settings">
      <Tabs
        className="settings-tabs"
        items={items}
        defaultActiveKey="projectSettings"
        activeKey={params.subSettings}
        onTabClick={handleTabChange}
      />
    </div>
  );
};
