import { Tooltip } from "antd";
import { ShowInDemoPageIcon } from "../../assets/Icons";
import { getENV } from "../../config";
import { EXAMPLE_PLAYER_LINK } from "../../consts";
import { Send } from "../../icons-and-animations/icons";

import "./Icons.scss"
interface Props {
    projectId: string;
    playerVersion?: string | null;
    scenes: string[];
    selectedDataID?: string;
    data: any;
    recordChanged?: boolean;
}

export function PreviewDemo({
    projectId,
    scenes,
    selectedDataID,
    data,
    playerVersion,
    recordChanged,
}: Props) {
    let url = `${EXAMPLE_PLAYER_LINK}?env=${getENV()}&p=${projectId}`;
    if (scenes) {
        url += `&scenes=${encodeURIComponent(scenes.join())}`;
    }

    if (selectedDataID) {
        url += `&id=${selectedDataID}`;
    }

    if (data && (!selectedDataID || recordChanged)) {
        url += `&data=${encodeURIComponent(JSON.stringify(data))}`;
    }

    return (
        <Tooltip
            placement="bottom"
            title={"Preview on Demo Page"}
        >
            <a
                style={{
                    display: "flex",
                    alignItems: "center",
                }}
                onClick={() => {
                    const currentVersion = playerVersion;
                    // Do nothing if the player has the latest version
                    if (currentVersion === "latest") {
                    }
                    // If there is no player version, use the one that the project has specified
                    else if (currentVersion === "WARN_NO_PLAYER_VERSION") {
                        console.warn(
                            "No player version specified, using project version"
                        );
                        console.warn("Project version: ", currentVersion);
                        url += `&v=${(currentVersion as string).replaceAll(
                            ".",
                            "-"
                        )}`;
                    }
                    // If there is a player version, use that
                    else {
                        url += `&v=${currentVersion}`;
                    }
                    // Open the URL trough code
                    window.open(url, "_blank");
                }}
                target="_blank"
                rel="noopener noreferrer"
            // title="Preview on demo page"
            >
                <div className="command-bar-icon">
                    <ShowInDemoPageIcon />
                </div>
            </a>
        </Tooltip>

    )
}