import { IFlowScene } from "@blings/blings-player/lib/src/SDK/sdk.api";
import { Select } from "antd";
import { IFlowDiagramModel } from "../stores/project/projectModel";
import "./SceneOrLineupSelect.scss";

interface Props {
  scenes: Array<string>;
  flowDiagram?: IFlowDiagramModel | null;
  value: Array<IFlowScene>;
  onFocus?: () => void;
  onChange?: (value: Array<IFlowScene>) => void;
  style?: React.CSSProperties;
  className?: string;
  limit?: boolean;
}
export default function SceneOrLineupSelect(props: Props) {
  const {
    scenes,
    flowDiagram,
    onFocus,
    onChange,
    value,
    style,
    className,
    limit,
  } = props;

  return (
    <Select
      className={"SceneOrLineupSelect " + (className ? className : "")}
      style={{ ...style }}
      onFocus={onFocus}
      value={value.map((scene) =>
        typeof scene === "string" ? scene : JSON.stringify(scene)
      )}
      onChange={(value) => {
        const values = value.map((v) => {
          try {
            return JSON.parse(v);
          } catch (e) {}
          return v;
        });
        onChange && onChange(values);
      }}
      mode="multiple"
      maxTagCount={limit ? 2 : undefined}
      maxTagTextLength={limit ? 8 : undefined}
    >
      {flowDiagram?.lineups.length ? (
        <>
          <Select.OptGroup key="Flows">
            {flowDiagram.lineups.map((lineup) => (
              <Select.Option key={lineup} value={JSON.stringify({ lineup })}>
                {lineup}
              </Select.Option>
            ))}
          </Select.OptGroup>
          <Select.OptGroup key="Scenes">
            {scenes.map((scene) => (
              <Select.Option key={scene} value={scene}>
                {scene}
              </Select.Option>
            ))}
          </Select.OptGroup>
        </>
      ) : (
        scenes.map((scene) => (
          <Select.Option key={scene} value={scene}>
            {scene}
          </Select.Option>
        ))
      )}
    </Select>
  );
}
