import { Space, Spin, Row } from "antd";
import { observer } from "mobx-react-lite";
import * as React from "react";
import { useMst } from "../../stores/Root";
import UploadNewFile from "./UploadNewFile";
import DataUploadsTable from "./DataUploadsTable";
import "./DataUpload.scss";
import { IFileUploadModel, IAccountModel } from "../../stores/accountStore";
import { IProjectModel } from "../../stores/project/projectModel";
import { IuploadDataModel } from "../../stores/uploadData";
// const { Title, Text, Paragraph } = Typography;

type Props = {
  loading: boolean;
  account: IAccountModel;
  fileUploads: IFileUploadModel[];
  projects: IProjectModel[];
  uploadData: IuploadDataModel;
};

const DataUploadsWrapper = observer(() => {
  const state = useMst((store) => {
    if (store.userStore.loadStatus === "LOADING") {
      return { loading: true };
    }

    return {
      loading: false,
      fileUploads: store.userStore.mainAccount?.fileUploads,
      projects: store.projectsStore.projects,
      uploadData: store.uploadDataStore,
      account: store.userStore.mainAccount,
    };
  });

  return <DataUploads {...state} />;
});

function DataUploads({ loading, ...props }: Props) {
  // React.useEffect(() => {
  //   rootStore.uploadDataStore.loadDataUploads();
  // }, []);
  return (
    <div className={"DataUploadsPage page-layout-padding"}>
      <Space direction="vertical" style={{ width: "100%" }}>
        <div className="DataUploads">
          <UploadNewFile
            uploadData={props.uploadData}
            onUpload={() => props.account.refreshFileUploads()}
            accountId={props.account.id}
          />
        </div>

        {loading ? (
          <Row justify="center" className="loading">
            <Spin />
          </Row>
        ) : (
          <DataUploadsTable {...props} />
        )}
      </Space>
    </div>
  );
}

export default DataUploadsWrapper;
