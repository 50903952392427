import * as React from "react";
import { Button, Card, Select, Space } from "antd";
import { observer } from "mobx-react-lite";
import { useParams } from "react-router-dom";
import { useMst } from "../../stores/Root";
import { IFileUploadsModel } from "../../stores/accountStore";
import { renderUploadTime } from "../DataUploads/DataUploadsTable";
const { Option } = Select;
const { useRef, useState } = React;

type Props = {
  fileName: string;
  fileUploads: IFileUploadsModel;
  saveDataSource: (filename: string | null) => void;
  downloadExampleCSV: () => void;
};

function ProjectDataSettings(props: Props) {
  const { fileUploads, fileName } = props;
  const selectRef = useRef(null);
  const [value, setValue] = useState(fileName || undefined);

  return (
    <Card size="small" title="Connect Data Source" actions={[]}>
      <Space direction="vertical" style={{ marginTop: 5 }}>
        <Space>
          <Select
            allowClear
            ref={selectRef}
            showSearch
            style={{ minWidth: 300 }}
            placeholder="data-upload source"
            optionFilterProp="text"
            value={value}
            onChange={setValue}
          >
            {fileUploads.map((fu) => {
              const fourCharsID = fu.fileName.slice(0, 4);
              const time = renderUploadTime(fu.createdAt);
              const name = fu.originalName || "";
              const details = `${time} [#${fourCharsID}]`;

              return (
                <Option
                  title={`${name} - ${details}`}
                  text={`${name} - ${details}`}
                  key={fu.fileName}
                  value={fu.fileName}
                >
                  <span className="fontW700">{name} </span>
                  {details}
                </Option>
              );
            })}
          </Select>
          <Button
            className="set-button"
            onClick={() => {
              props.saveDataSource(value || null);
              console.log(value);
            }}
            disabled={value ? value === fileName : !!value === !!fileName}
            type={"primary"}
          >
            Set
          </Button>
        </Space>
        <a onClick={props.downloadExampleCSV}>Download example csv</a>
      </Space>
    </Card>
  );
}

const ProjectDataSettingsWrapper = observer(() => {
  const { id } = useParams<{ id: string }>();

  const state = useMst((store) => {
    const project = store.projectsStore.selectedProject;
    return {
      fileName: project && project.fileName,
      fileUploads:
        store.userStore.accounts?.[0].fileUploads.slice().reverse() || [],
      saveDataSource: project && project.saveFileName,
      downloadExampleCSV: project?.downloadExampleCSV,
    };
  });

  return (
    <div className="connect-data-source">
      <ProjectDataSettings {...state} />
    </div>
  );
});

export default ProjectDataSettingsWrapper;
