import { Modal, Select } from "antd";
import { useEffect, useState } from "react";
import { useMst } from "../../../../stores/Root";
import { IFileUploadModel } from "../../../../stores/accountStore";
import { IProjectModel } from "../../../../stores/project/projectModel";
import { IAccountModel } from "../../../../stores/accountStore";
import { IuploadDataModel } from "../../../../stores/uploadData";
import UploadNewFile from "../../../DataUploads/UploadNewFile";
import {
  LandingPageIntegration,
  LandingPageIntegrationState,
} from "./LandingPageIntegration/LandingPageIntegration";
import { IntegrationLink } from "./IntegrationLink/IntegrationLink";
import { renderUploadTime } from "../../../DataUploads/DataUploadsTable";
import "./CsvIntegration.scss";
import { observer } from "mobx-react-lite";
import { BlingsAnchorButton } from "../../../../components/BlingsAnchorButton/BlingsAnchorButton";

type Props = {
  project: IProjectModel;
  state: LandingPageIntegrationState;
  setState: React.Dispatch<React.SetStateAction<LandingPageIntegrationState>>;
  savedState: LandingPageIntegrationState;
};
export const CsvIntegration = (props: Props) => {
  const { project, state, setState, savedState } = props;
  return (
    <div className="CsvIntegration">
      <LandingPageIntegration
        project={project}
        state={state}
        setState={setState}
        savedState={savedState}
      />
      <DataIntegration projectAliasId={state.alias} />
    </div>
  );
};

type DataIntegrationProps = {
  projectAliasId: string;
};
const DataIntegration = observer((_: DataIntegrationProps) => {
  const state: {
    fileUploads: Array<IFileUploadModel>;
    projects: Array<IProjectModel>;
    accountStore: IAccountModel;
    uploadDataStore: IuploadDataModel;
    downloadExampleCSV: () => void;
    selectedCsvFileName: string | null;
    saveDataSource: (filename: string | null) => void;
    projectAliasId: string;
    accountAliasId: string;
    projectAccountDomain: string;
  } = useMst((store) => {
    const accountId =
      store.projectsStore.selectedProject?.projectAccountId || undefined;
    const accountStore = store.userStore.accountFromId(accountId);
    return {
      fileUploads: accountStore?.fileUploads || [],
      projects: store.projectsStore.projects,
      accountStore: accountStore,
      uploadDataStore: store.uploadDataStore,
      saveDataSource: store.projectsStore.selectedProject?.saveFileName,
      downloadExampleCSV:
        store.projectsStore.selectedProject?.downloadExampleCSV,
      selectedCsvFileName: store.projectsStore.selectedProject?.fileName,
      projectAliasId: store.projectsStore.selectedProject?.aliasId,
      accountAliasId:
        store.projectsStore.selectedProject?.account?.aliasId || "",
      projectAccountDomain:
        store?.projectsStore?.selectedProject?.projectAccountDomain || "",
    };
  });
  const [uploadedFileName, setUploadedFileName] = useState<string>("");
  const {
    fileUploads,
    projects,
    accountStore,
    uploadDataStore,
    downloadExampleCSV,
    saveDataSource,
    selectedCsvFileName,
    projectAliasId,
    accountAliasId,
    projectAccountDomain,
  } = state;
  const initialCsvIndex = fileUploads.findIndex(
    (file: IFileUploadModel) => file.fileName === selectedCsvFileName
  );
  const [selected, setSelected] = useState<number | null>(
    initialCsvIndex > -1 ? initialCsvIndex : null
  );
  const [previouslySelected, setPreviouslySelected] = useState<number | null>(
    selected
  );
  const fileProjectMap = projects.reduce<{ [fileName: string]: Array<string> }>(
    (acc, project) => {
      const { fileName } = project;
      if (!fileName) return acc;
      acc[fileName] = acc[fileName] || [];
      acc[fileName].push(project.title);
      return acc;
    },
    {}
  );
  const options = [
    { label: "Upload a new CSV", value: -1 },
    {
      label: "USE EXISTING CSV",
      options: fileUploads.map((fu, i) => {
        const fourCharsID = fu.fileName.slice(0, 4);
        const time = renderUploadTime(fu.createdAt);
        const name = fu.originalName || "";
        const details = `${time} [#${fourCharsID}]`;
        return {
          label: (
            <div title={`${name} - ${details}`} key={fu.fileName}>
              <span className="fontW700">{name} </span>
              {details}
              {fileProjectMap[fu.fileName] ? (
                <span>{fileProjectMap[fu.fileName].join(",")}</span>
              ) : null}
            </div>
          ),
          value: i,
        };
      }),
    },
  ];
  useEffect(() => {
    if (
      options[1].options &&
      selected !== null &&
      options[1].options[selected]
    ) {
      saveDataSource(options[1].options[selected].label.key as string);
    }
  }, [selected]);
  return (
    <div className="DataIntegration">
      <div>
        <h1 className="page-subtitle">2. Data Integration </h1>
        <div>
          Select or upload a CSV file containing your dynamic data keys for
          video personalization.
        </div>
        <div>Make sure to include an "id" column.</div>
        <Select
          placeholder="Select or upload a CSV file"
          options={options}
          value={selected}
          size="large"
          onChange={(e) => {
            if (e != null && e > -1) {
              setPreviouslySelected(e);
            }
            setSelected(e);
          }}
          style={{ minWidth: "600px", margin: "1rem 0" }}
        />
        <Modal
          open={selected === -1}
          okButtonProps={{ disabled: uploadedFileName === "" }}
          onOk={() => {
            setSelected(
              fileUploads.findIndex(
                (file: IFileUploadModel) => file.fileName === uploadedFileName
              )
            );
            saveDataSource(uploadedFileName);
          }}
          onCancel={() => {
            setSelected(previouslySelected);
          }}
        >
          <h2>Upload a new CSV</h2>
          <UploadNewFile
            uploadData={uploadDataStore}
            onUpload={(fileName) => {
              accountStore.refreshFileUploads();
              setUploadedFileName(fileName);
            }}
            accountId={accountStore.id}
          />
        </Modal>
        <div className="csv-selection-extra">
          <BlingsAnchorButton onClick={downloadExampleCSV}>
            Download the CSV with your dynamic data keys
          </BlingsAnchorButton>
        </div>
        <IntegrationLink
          accountAliasId={accountAliasId}
          projectAliasId={projectAliasId}
          projectAccountDomain={projectAccountDomain}
        />
      </div>
      <div>
        {/* <video
        height={'70%'}
          autoPlay
          src="https://player.vimeo.com/external/371817283.sd.mp4?s=56639e00db07ad3f26d837314e3da531bad01b1b&profile_id=164&oauth2_token_id=57447761"
        ></video> */}
      </div>
    </div>
  );
});
