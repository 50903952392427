import { cast, getSnapshot, Instance, types } from "mobx-state-tree";
import { Experiment, ExperimentOptimizationTechnique } from "../API";
import _ from "lodash";

const SuccessFactorsModel = types.model({
  conversionSuccessData: types.number,
  engagementSuccessData: types.number,
  selectionCount: types.number,
  watchTimeSuccessData: types.number,
});

const VariantModel = types.model({
  id: types.identifier,
  value: types.union(types.string, types.undefined),
  successFactors: SuccessFactorsModel,
});

export const ExperimentModel = types.model({
  id: types.string,
  variants: types.array(VariantModel),
  type: types.string,
});

const SelectedVariantSet: { [key: string]: string } = {};

export const ExperimentStore = types
  .model({
    unchangedExperiments: types.maybeNull(types.array(ExperimentModel)),
    unchangedDraftExperiments: types.maybeNull(types.array(ExperimentModel)),
    changedExperiments: types.maybeNull(types.array(ExperimentModel)),
    changedDraftExperiments: types.maybeNull(types.array(ExperimentModel)),
    selectedExperimentOptimizationTechnique: types.maybeNull(
      types.frozen<ExperimentOptimizationTechnique>()
    ),
  })
  .views((self) => ({
    get unchangedDraftExperimentsCleaned() {
      return self.unchangedDraftExperiments || [];
    },
    get unchangedExperimentsCleaned() {
      return self.unchangedExperiments || [];
    },
    get experiments() {
      return _.cloneDeep(getSnapshot(self.changedExperiments || {}));
    },
    get selectedVariantSet() {
      const variantSelectionSet: { experimentId: string; variantId: string }[] =
        [];
      for (const experimentId in SelectedVariantSet) {
        variantSelectionSet.push({
          experimentId,
          variantId: SelectedVariantSet[experimentId],
        });
      }
      return variantSelectionSet;
    },
    get hasUnsavedChanges() {
      return !_.isEqual(self.unchangedExperiments, self.changedExperiments);
    },
  }))
  // Setters and getters that need parameters
  .actions((self) => ({
    getExperimentById(experimentId: string, id: string) {
      if (!self.changedExperiments) return null;
      return self.changedExperiments
        .find((experiment) => experiment.id === experimentId)
        ?.variants.find((variant) => variant.id === id);
    },
    getExperiment(experimentId: string) {
      if (!self.changedExperiments) return null;
      return self.changedExperiments.find((v) => v.id === experimentId);
    },
    getDraftExperiment(experimentId: string) {
      if (!self.changedDraftExperiments) return null;
      return self.changedDraftExperiments.find((v) => v.id === experimentId);
    },
    getSelectedVExperimentVariant(experimentId: string) {
      if (SelectedVariantSet[experimentId] === undefined) return null;
      return this.getExperimentById(
        experimentId,
        SelectedVariantSet[experimentId]
      );
    },
    getVariantByIndex(experimentId: string, variantIndex: number) {
      if (!self.changedExperiments) return null;
      const experiment = self.changedExperiments.find(
        (v) => v.id === experimentId
      );
      if (!experiment) return null;
      return experiment.variants[variantIndex];
    },
    getSelectedVariantForExperiment(experimentId: string) {
      return SelectedVariantSet[experimentId];
    },
    setExperimentOptimizationTechnique(type: ExperimentOptimizationTechnique) {
      self.selectedExperimentOptimizationTechnique = type;
    },
    setSelectedVariantById(experimentId: string, variantId: string) {
      if (!self.changedExperiments) return;
      const experiment = self.changedExperiments.find(
        (exp) => exp.id === experimentId
      );
      if (!experiment) throw new Error("No experiment was found");
      SelectedVariantSet[experimentId] = variantId;
    },
    setSelectedVariantByIndex(experimentId: string, variantIndex: number) {
      if (!self.changedExperiments) return;
      const experiment = self.changedExperiments.find(
        (exp) => exp.id === experimentId
      );
      if (!experiment) throw new Error("No experiment was found");
      SelectedVariantSet[experimentId] = experiment.variants[variantIndex].id;
    },
    setSelectedVariantByValue(experimentId: string, value: string) {
      if (!self.changedExperiments) return;
      const experiment = self.changedExperiments.find(
        (exp) => exp.id === experimentId
      );
      if (!experiment) throw new Error("No experiment was found");
      SelectedVariantSet[experimentId] = value;
    },
  }))
  // Checks
  .actions((self) => ({
    isExperimentDraftUnsaved(experimentId: string) {
      if (!self.changedDraftExperiments) return false;
      const changedExperiment = self.changedDraftExperiments.find(
        (exp) => exp.id === experimentId
      );
      const unchangedExperiment = self.unchangedDraftExperiments?.find(
        (exp) => exp.id === experimentId
      );
      return !_.isEqual(changedExperiment, unchangedExperiment);
    },
    isExperimentUnsaved(experimentId: string) {
      if (!self.changedExperiments) return false;
      const changedExperiment = self.changedExperiments.find(
        (exp) => exp.id === experimentId
      );
      const unchangedExperiment = self.unchangedExperiments?.find(
        (exp) => exp.id === experimentId
      );
      return !_.isEqual(changedExperiment, unchangedExperiment);
    },
  }))
  // CRUD actions
  .actions((self) => ({
    createNewExperiment(firstValue = "", newOptionValue = ""): string {
      if (
        !self.changedExperiments ||
        self.changedExperiments.length === 0 ||
        self.changedExperiments === null
      ) {
        self.changedExperiments = cast([]);
        self.unchangedExperiments = cast([]);
      }
      self.changedExperiments?.push({
        id: generateShortUUID(),
        type: "connector-experiment",
        variants: [
          {
            id: generateShortUUID(),
            successFactors: {
              conversionSuccessData: 0,
              engagementSuccessData: 0,
              selectionCount: 0,
              watchTimeSuccessData: 0,
            },
            value: firstValue,
          },
          {
            id: generateShortUUID(),
            successFactors: {
              conversionSuccessData: 0,
              engagementSuccessData: 0,
              selectionCount: 0,
              watchTimeSuccessData: 0,
            },
            value: newOptionValue,
          },
        ],
      });
      // @ts-ignore
      return self.changedExperiments[self.changedExperiments.length - 1].id;
    },
    createNewDraftExperiment(firstValue = "", newOptionValue = ""): string {
      if (
        !self.changedDraftExperiments ||
        self.changedDraftExperiments.length === 0 ||
        self.changedDraftExperiments === null
      ) {
        self.changedDraftExperiments = cast([]);
        self.unchangedDraftExperiments = cast([]);
      }
      self.changedDraftExperiments?.push({
        id: generateShortUUID(),
        type: "connector-experiment",
        variants: [
          {
            id: generateShortUUID(),
            successFactors: {
              conversionSuccessData: 0,
              engagementSuccessData: 0,
              selectionCount: 0,
              watchTimeSuccessData: 0,
            },
            value: firstValue,
          },
          {
            id: generateShortUUID(),
            successFactors: {
              conversionSuccessData: 0,
              engagementSuccessData: 0,
              selectionCount: 0,
              watchTimeSuccessData: 0,
            },
            value: newOptionValue,
          },
        ],
      });

      if (self.changedDraftExperiments)
        return self.changedDraftExperiments[
          self.changedDraftExperiments.length - 1
        ].id;
      throw new Error("No experiment was found");
    },

    createNewVariant(experimentId: string, value = "") {
      if (!self.changedExperiments) return;
      const experiment = self.changedExperiments.find(
        (exp) => exp.id === experimentId
      );
      if (!experiment) throw new Error("No experiment was found");
      experiment.variants.push({
        id: generateShortUUID(),
        successFactors: {
          conversionSuccessData: 0,
          engagementSuccessData: 0,
          selectionCount: 0,
          watchTimeSuccessData: 0,
        },
        value,
      });
    },
    createNewDraftVariant(experimentId: string, value = "") {
      if (!self.changedDraftExperiments) return;
      const experiment = self.changedDraftExperiments.find(
        (exp) => exp.id === experimentId
      );
      if (!experiment) throw new Error("No experiment was found");
      experiment.variants.push({
        id: generateShortUUID(),
        successFactors: {
          conversionSuccessData: 0,
          engagementSuccessData: 0,
          selectionCount: 0,
          watchTimeSuccessData: 0,
        },
        value,
      });
    },
    deleteVariantById(experimentId: string, variantId: string) {
      if (!self.changedExperiments) return;
      const experiment = self.changedExperiments.find(
        (exp) => exp.id === experimentId
      );
      if (!experiment) throw new Error("No experiment was found");
      const variantIndex = experiment.variants.findIndex(
        (variant) => variant.id === variantId
      );
      if (variantIndex !== -1) {
        experiment.variants.splice(variantIndex, 1);
      } else {
        throw new Error("No variant was found");
      }
      if (experiment.variants.length <= 1) {
        this.deleteExperimentById(experimentId);
      }
    },
    deleteVariantByIndex(experimentId: string, variantIndex: number) {
      if (!self.changedExperiments) return;
      const experiment = self.changedExperiments.find(
        (exp) => exp.id === experimentId
      );
      if (!experiment || !experiment.variants)
        throw new Error("No experiment or variant was found");
      experiment.variants.splice(variantIndex, 1);
      if (experiment.variants.length <= 1) {
        this.deleteExperimentById(experimentId);
      }
    },
    deleteDraftVariantByIndex(experimentId: string, variantIndex: number) {
      if (!self.changedDraftExperiments) return;
      const experiment = self.changedDraftExperiments.find(
        (exp) => exp.id === experimentId
      );
      if (!experiment || !experiment.variants)
        throw new Error("No experiment or variant was found");
      experiment.variants.splice(variantIndex, 1);
      if (experiment.variants.length <= 1) {
        this.deleteDraftExperimentById(experimentId);
      }
    },
    deleteExperimentById(experimentId: string) {
      if (!self.changedExperiments) return;
      if (self.changedExperiments.length > 1) {
        const experimentIndex = self.changedExperiments.findIndex(
          (exp) => exp.id === experimentId
        );
        if (experimentIndex !== -1) {
          self.changedExperiments.splice(experimentIndex, 1);
        } else {
          throw new Error("No experiment was found");
        }
      } else {
        self.changedExperiments = null;
      }
    },
    deleteDraftExperimentById(experimentId: string) {
      if (!self.changedDraftExperiments) return;
      if (self.changedDraftExperiments.length > 1) {
        const experimentIndex = self.changedDraftExperiments.findIndex(
          (exp) => exp.id === experimentId
        );
        if (experimentIndex !== -1) {
          self.changedDraftExperiments.splice(experimentIndex, 1);
        } else {
          throw new Error("No experiment was found");
        }
      } else {
        self.changedDraftExperiments = null;
      }
    },
    updateVariantById(
      experimentId: string,
      variantId: string,
      value: string | undefined
    ) {
      if (!self.changedExperiments) return;
      const experiment = self.changedExperiments.find(
        (exp) => exp.id === experimentId
      );
      if (!experiment) throw new Error("No experiment was found");
      const variant = experiment.variants.find(
        (variant) => variant.id === variantId
      );
      if (!variant) throw new Error("Specified variant was not found");
      variant.value = value as string;
    },
    updateVariantByIndex(
      experimentId: string,
      variantIndex: number,
      value: string | undefined
    ) {
      if (!self.changedExperiments) return;
      self.setSelectedVariantByIndex(experimentId, variantIndex);
      const experiment = self.changedExperiments.find(
        (exp) => exp.id === experimentId
      );
      if (!experiment) throw new Error("No experiment was found");
      experiment.variants[variantIndex].value = value as string;
    },
    updateDraftVariantByIndex(
      experimentId: string,
      variantIndex: number,
      value: string | undefined
    ) {
      if (!self.changedDraftExperiments) return;
      const experiment = self.changedDraftExperiments.find(
        (exp) => exp.id === experimentId
      );
      if (!experiment) throw new Error("No experiment was found");
      experiment.variants[variantIndex].value = value as string;
    },
  }))
  // Complex actions
  .actions((self) => ({
    experimentHasOptionSelected(experimentId: string) {
      return SelectedVariantSet[experimentId] !== undefined;
    },
    replaceExperiments(
      newExperimentSet: Array<Experiment> | undefined,
      draft = false
    ) {
      if (newExperimentSet) {
        if (draft) {
          self.unchangedDraftExperiments = cast(
            newExperimentSet.map((experiment) => {
              return ExperimentModel.create({
                id: experiment.id,
                type: experiment.type,
                variants: experiment.variants?.map((variant) => {
                  return VariantModel.create({
                    id: variant.id,
                    value: variant.value,
                    successFactors: SuccessFactorsModel.create({
                      conversionSuccessData:
                        variant.successFactors?.conversionSuccessData || 0,
                      engagementSuccessData:
                        variant.successFactors?.engagementSuccessData || 0,
                      selectionCount:
                        variant.successFactors?.selectionCount || 0,
                      watchTimeSuccessData:
                        variant.successFactors?.watchTimeSuccessData || 0,
                    }),
                  });
                }),
              });
            })
          );
          self.changedDraftExperiments = cast(
            newExperimentSet.map((variant) => {
              return ExperimentModel.create({
                id: variant.id,
                type: variant.type,
                variants: variant.variants?.map((variant) => {
                  return VariantModel.create({
                    id: variant.id,
                    value: variant.value,
                    successFactors: SuccessFactorsModel.create({
                      conversionSuccessData:
                        variant.successFactors?.conversionSuccessData || 0,
                      engagementSuccessData:
                        variant.successFactors?.engagementSuccessData || 0,
                      selectionCount:
                        variant.successFactors?.selectionCount || 0,
                      watchTimeSuccessData:
                        variant.successFactors?.watchTimeSuccessData || 0,
                    }),
                  });
                }),
              });
            })
          );
        } else {
          self.unchangedExperiments = cast(
            newExperimentSet.map((variant) => {
              return ExperimentModel.create({
                id: variant.id,
                type: variant.type,
                variants: variant.variants?.map((variant) => {
                  return VariantModel.create({
                    id: variant.id,
                    value: variant.value,
                    successFactors: SuccessFactorsModel.create({
                      conversionSuccessData:
                        variant.successFactors?.conversionSuccessData || 0,
                      engagementSuccessData:
                        variant.successFactors?.engagementSuccessData || 0,
                      selectionCount:
                        variant.successFactors?.selectionCount || 0,
                      watchTimeSuccessData:
                        variant.successFactors?.watchTimeSuccessData || 0,
                    }),
                  });
                }),
              });
            })
          );
          self.changedExperiments = cast(
            newExperimentSet.map((variant) => {
              return ExperimentModel.create({
                id: variant.id,
                type: variant.type,
                variants: variant.variants?.map((variant) => {
                  return VariantModel.create({
                    id: variant.id,
                    value: variant.value,
                    successFactors: SuccessFactorsModel.create({
                      conversionSuccessData:
                        variant.successFactors?.conversionSuccessData || 0,
                      engagementSuccessData:
                        variant.successFactors?.engagementSuccessData || 0,
                      selectionCount:
                        variant.successFactors?.selectionCount || 0,
                      watchTimeSuccessData:
                        variant.successFactors?.watchTimeSuccessData || 0,
                    }),
                  });
                }),
              });
            })
          );
        }
      } else {
        if (draft) {
          self.unchangedDraftExperiments = null;
          self.changedDraftExperiments = null;
        } else {
          self.unchangedExperiments = null;
          self.changedExperiments = null;
        }
      }
    },

    changedToUnchanged() {
      self.unchangedExperiments = cast(_.cloneDeep(self.changedExperiments));
    },
    changedToUnchangedSpecificExperiment(experimentId: string | undefined) {
      if (!experimentId) return;
      const experiment = self.changedExperiments?.find(
        (exp) => exp.id === experimentId
      );
      const unchangedExperiment = self.unchangedExperiments?.find(
        (exp) => exp.id === experimentId
      );
      if (!experiment && !unchangedExperiment) return;
      if (experiment && !unchangedExperiment) {
        // variant got created
        self.unchangedExperiments?.push(_.cloneDeep(experiment));
      }

      if (
        !experiment &&
        unchangedExperiment &&
        self?.unchangedExperiments?.length
      ) {
        // variant got deleted
        const unchangedExperimentIndex =
          self.unchangedExperiments.indexOf(unchangedExperiment);
        self?.unchangedExperiments?.splice(unchangedExperimentIndex, 1);
      }
      if (
        experiment &&
        unchangedExperiment &&
        self?.unchangedExperiments?.length
      ) {
        //if variant exists in both, get the updated options
        const unchangedExperimenttIndex =
          self.unchangedExperiments.indexOf(unchangedExperiment);
        self.unchangedExperiments[unchangedExperimenttIndex] =
          _.cloneDeep(experiment);
      }
    },
    changedToUnchangedSpecificDraftExperiment(
      experimentId: string | undefined
    ) {
      if (!experimentId) return;
      const experiment = self.changedDraftExperiments?.find(
        (exp) => exp.id === experimentId
      );
      const unchangedExperiment = self.unchangedDraftExperiments?.find(
        (exp) => exp.id === experimentId
      );
      if (!experiment && !unchangedExperiment) return;
      if (experiment && !unchangedExperiment) {
        // variant got created
        self.unchangedDraftExperiments?.push(_.cloneDeep(experiment));
      }

      if (
        !experiment &&
        unchangedExperiment &&
        self?.unchangedDraftExperiments?.length
      ) {
        // variant got deleted
        const unchangedExperimentIndex =
          self.unchangedDraftExperiments.indexOf(unchangedExperiment);
        self?.unchangedDraftExperiments?.splice(unchangedExperimentIndex, 1);
      }
      if (
        experiment &&
        unchangedExperiment &&
        self?.unchangedDraftExperiments?.length
      ) {
        //if variant exists in both, get the updated options
        const unchangedExperimentIndex =
          self.unchangedDraftExperiments.indexOf(unchangedExperiment);
        self.unchangedDraftExperiments[unchangedExperimentIndex] =
          _.cloneDeep(experiment);
      }
    },
    mergeWithExperimentsFromSubscription(
      newExperimentSet: Array<Experiment> | undefined
    ) {
      if (!newExperimentSet) return;

      let unchangedExperiments = self.unchangedExperiments;
      let changedExperiments = self.changedExperiments;
      if (!unchangedExperiments || !changedExperiments) return;

      // Create a map of changed variants for quick lookup
      const changedExperimentsMap = new Map();
      changedExperiments.forEach((exp) => {
        changedExperimentsMap.set(exp.id, exp);
      });

      // Process new variants
      const mergedExperiments = newExperimentSet.map((newExperiment) => {
        // Check if this variant has been modified by the user
        const changedExperiments = changedExperimentsMap.get(newExperiment.id);
        if (
          changedExperiments &&
          !_.isEqual(
            changedExperiments,
            unchangedExperiments?.find((v) => v.id === newExperiment.id)
          )
        ) {
          // User has made changes, keep the user's version
          return changedExperiments;
        } else {
          // No changes made by user, use the new variant
          return ExperimentModel.create({
            id: newExperiment.id,
            type: newExperiment.type,
            variants: newExperiment?.variants?.map((variant) => {
              return VariantModel.create({
                id: variant.id,
                value: variant.value,
                successFactors: variant.successFactors
                  ? SuccessFactorsModel.create({
                      conversionSuccessData:
                        variant.successFactors.conversionSuccessData,
                      engagementSuccessData:
                        variant.successFactors.engagementSuccessData,
                      selectionCount: variant.successFactors.selectionCount,
                      watchTimeSuccessData:
                        variant.successFactors.watchTimeSuccessData,
                    })
                  : {
                      conversionSuccessData: 0,
                      engagementSuccessData: 0,
                      selectionCount: 0,
                      watchTimeSuccessData: 0,
                    },
              });
            }),
          });
        }
      });
      // Update the store
      self.unchangedExperiments = cast(_.cloneDeep(mergedExperiments));
      self.changedExperiments = cast(_.cloneDeep(mergedExperiments));
    },
  }));

export function createExperimentStoreFromVersions(
  experimentOptimizationTechnique?: ExperimentOptimizationTechnique | null,
  publishedExperiment?: Experiment[] | null,
  draftExperiments?: Experiment[] | null
) {
  const store = ExperimentStore.create({
    unchangedExperiments: null,
    unchangedDraftExperiments: null,
    changedExperiments: null,
    changedDraftExperiments: null,
    selectedExperimentOptimizationTechnique: null,
  });

  store.replaceExperiments(publishedExperiment || []);
  store.replaceExperiments(draftExperiments || [], true);
  store.setExperimentOptimizationTechnique(
    experimentOptimizationTechnique ?? ExperimentOptimizationTechnique.DISABLED
  );

  return store;
}

export type IExperimentModel = Instance<typeof ExperimentModel>;

//#region Utils
function generateShortUUID(): string {
  // Gera um número aleatório e converte para base 16 (hexadecimal)
  // Depois, corta a string para garantir que tenha exatamente 8 caracteres
  return Math.random().toString(16).slice(2, 10);
}
//#endregion
