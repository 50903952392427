import { IFlowScene } from '@blings/blings-player/lib/src/SDK/sdk.api';
import React, { createContext, useState, useContext, ReactNode } from 'react';

// Define the shape of the context
interface PlayerContextType {
  selectedScenes: IFlowScene[];
  setSelectedScenes: React.Dispatch<React.SetStateAction<IFlowScene[]>>;
}

// Create the Context with a default value (can be null initially)
const PlayerContext = createContext<PlayerContextType | undefined>(undefined);

// Create a Provider Component with TypeScript Props
interface PlayerProviderProps {
  children: ReactNode; // ReactNode allows children to be any valid React component or element
}

export const PlayerProvider = ({ children }: PlayerProviderProps) => {
  const [selectedScenes, setSelectedScenes] = useState<IFlowScene[]>([]);

  return (
    <PlayerContext.Provider value={{ selectedScenes, setSelectedScenes }}>
      {children}
    </PlayerContext.Provider>
  );
};

// Hook for easy access to the context
export const usePlayerContext = (): PlayerContextType => {
  const context = useContext(PlayerContext);
  if (!context) {
    throw new Error("usePlayerContext must be used within a PlayerProvider");
  }
  return context;
};
