import { IProjectModel } from "../../stores/project/projectModel";
import {
  Card,
  Checkbox,
  Divider,
  InputNumber,
  Popover,
  Radio,
  RadioChangeEvent,
  Switch,
  UploadFile,
} from "antd";
import { post } from "aws-amplify/api";
import { useState } from "react";
import { jsonSchemaGetExamples } from "../../helpers/jsonSchema.helpers";
import { bool } from "aws-sdk/clients/signer";
import { ColorPicker } from "../../components/ColorPicker";
import { useMst } from "../../stores/Root";
import "./PlayerSettings.scss";
import {
  BLINGS_COLOR,
  GRAY_COLOR,
  WHITE40,
  WHITE80,
} from "../../utils/color.consts";
import { getENV } from "../../config";
import { BlingsBtn } from "../../components/antd-extensions/blings-btn.component";
import { AsyncOpState } from "../../types/enums/async-op-states";
import timeline from "../../assets/timeline.png";
import player_settings_bg from "../../assets/player_settings_bg.gif";
import player_settings_loader from "../../assets/player_settings_loader.gif";
import storymode_color_control from "../../assets/storymode_color_control.png";
import timeline_color_control from "../../assets/timeline_color_control.png";
import thumb from "../../assets/thumb.png";
import story_progress from "../../assets/story_progress.png";
import story_rail from "../../assets/story_rail.png";
import rail from "../../assets/rail.png";
import progress from "../../assets/progress.png";
import storiesMode from "../../assets/storiesMode.png";
import {
  FEEDBACK_OPTIONS,
  FEEDBACK_TIME,
  NEW_REST_API,
  NEW_REST_API__V2__UTILS__GENERATE_SCREENSHOT,
} from "../../consts";
import { BlingsInput } from "../../components/BlingsInput/BlingsInput";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { PlayerComponent } from "../ProjectDemoPage/PlayerComponent";

type Props = {
  project: IProjectModel;
};
export const PlayerSettingsUI = ({ project }: Props) => {
  const state = useMst((store) => {
    const selectedProject = store.projectsStore.selectedProject;
    return {
      selectedProject,
      playerSettings: selectedProject?.playerSettings,
    };
  });
  const {
    uploadAssetToProject,
  }: {
    uploadAssetToProject: (
      file: File | UploadFile<any>,
      s3Folder: string,
      cb?: ((key: string) => void) | undefined,
      progressCallback?: any
    ) => Promise<void>;
  } = useMst((state) => {
    const { uploadAssetToProject } = state.liveControlStore;
    return { uploadAssetToProject };
  });
  const [saveState, setSaveState] = useState<AsyncOpState>(
    AsyncOpState.Changed
  );
  const [currentThumbnail, setCurrentThumbnail] = useState<string | undefined>(
    project.thumbS3Url || undefined
  );

  const data = jsonSchemaGetExamples(
    JSON.parse(project.stateJsonSchemaStr || "{}")
  );
  const [posterFrame, setPosterFrame] = useState<number>(
    state.playerSettings?.posterFrame || 30
  );
  const [loadingImage, setLoadingImage] = useState<string>(
    state.playerSettings?.loadingImage || undefined
  );
  const [loadingText, setLoadingText] = useState<string>(
    state.playerSettings?.loadingText || undefined
  );
  const [loaderColor, setLoaderColor] = useState<string>(
    state.playerSettings?.color_loader || BLINGS_COLOR
  );
  const [buttonsColor, setButtonsColor] = useState<string>(
    state.playerSettings?.color_ctrlBtns || GRAY_COLOR
  );
  const [backgroundColor, setBackgroundColor] = useState<string>(
    state.playerSettings?.color_bg || "transparent"
  );
  const [progressColor, setProgressColor] = useState<string>(
    state.playerSettings?.color_progress || GRAY_COLOR
  );
  const [railColor, setRailColor] = useState<string>(
    state.playerSettings?.color_rail || GRAY_COLOR
  );
  const [thumbColor, setThumbColor] = useState<string>(
    state.playerSettings?.color_thumb || GRAY_COLOR
  );
  const [autoReplay, setAutoReplay] = useState<bool>(
    state.playerSettings?.autoReplay || false
  );
  const [autoplay, setAutoplay] = useState<bool>(
    state.playerSettings?.autoplay || false
  );
  const [autoplay_delay, setAutoplay_delay] = useState<number>(
    state.playerSettings?.autoplay_delay || 0
  );
  const [autoplayOption, setAutoplayOption] = useState<"on" | "off" | "delay">(
    state.playerSettings?.autoplay
      ? state.playerSettings?.autoplay_delay
        ? "delay"
        : "on"
      : "off"
  );
  const [muted, setMuted] = useState<bool>(
    state.playerSettings?.muted || false
  );
  const [showTimeline, setShowTimeline] = useState<bool>(
    state.playerSettings?.showTimeline !== undefined &&
      state.playerSettings?.showTimeline === true
  );
  const [storieMode, setStorieMode] = useState<bool>(
    state.playerSettings?.storiesMode || false
  );
  const [currentOpenField, setCurrentOpenField] = useState<number>(0);
  const clickField = (fieldClicked: number) => {
    if (fieldClicked === currentOpenField) {
      setCurrentOpenField(0);
    } else {
      setCurrentOpenField(fieldClicked);
    }
  };
  const handleTimeLineChange = (e: bool) => {
    setShowTimeline(e);
    !e && handleStoryModeChange(false);
  };
  const handleStoryModeChange = (bool: boolean) => {
    setStorieMode(bool);
    if (bool) {
      setProgressColor(WHITE80);
      setRailColor(WHITE40);
    } else {
      setProgressColor(GRAY_COLOR);
      setRailColor(GRAY_COLOR);
    }
  };
  const playerSettingsForPlayer = {
    posterFrame: posterFrame,
    colors: {
      loader: loaderColor,
      ctrlBtns: buttonsColor,
      rail: railColor,
      progress: progressColor,
      thumb: thumbColor,
      bg: backgroundColor,
    },
    autoplay: autoplay as boolean | number, // The player only accepts a number or a boolean
    autoReplay: autoReplay,
    muted: muted,
    showTimeline: showTimeline,
    storiesMode: storieMode,
    showBlingsLogo: state.playerSettings?.showBlingsLogo || 0,
    blingsLogoColor: state.playerSettings?.blingsLogoColor,
  };
  const majorVersion = parseInt(
    state.selectedProject?.playerVersionToUse?.split(".")[0] as string
  );
  if (majorVersion >= 4) {
    // Delayed autoplay is only available in 4.13.0 and above
    // Autoplay delay is set in ms on the player but in seconds for the platform and the store
    playerSettingsForPlayer.autoplay = autoplay
      ? autoplay_delay
        ? autoplay_delay * 1000
        : 0
      : false;
  }

  const PlayerSettingsInput: { [key: string]: any } = {
    posterFrame: posterFrame,
    showTimeline: showTimeline,
    storiesMode: storieMode,
    color_loader: loaderColor,
    color_ctrlBtns: buttonsColor,
    color_rail: railColor,
    color_progress: progressColor,
    color_thumb: thumbColor,
    color_bg: backgroundColor,
    muted: muted,
    autoplay: autoplay,
    autoplay_delay:
      autoplay_delay && autoplay_delay != null ? autoplay_delay : 0,
    autoReplay: autoReplay,
    showBlingsLogo: state.playerSettings?.showBlingsLogo || 0,
    blingsLogoColor: state.playerSettings?.blingsLogoColor,
    loadingImage: loadingImage,
    loadingText: loadingText,
  };

  async function generateThumbnailForProject() {
    // Create the default player options
    //{ "{\"project\": {\"id\": \"autolead1\", \"env\": \"dev\"}, \"settings\": { \"maxLoadingTime\": 10000}, \"scenes\": [\"main\"], \"data\": {\"firstName\": \"Carlos\"}}"
    const project = {
      id: state.selectedProject?.id,
      env: getENV(),
    };
    const scenes = [state.selectedProject.videoPartNames[0]];
    const settings = {
      maxLoadingTime: 10000,
    };
    const playerOptions = {
      project: project,
      scenes: scenes,
      data: data,
      settings: settings,
    };

    await post({
      apiName: NEW_REST_API,
      path: NEW_REST_API__V2__UTILS__GENERATE_SCREENSHOT(project.id),
      options: {
        body: playerOptions,
      },
    }).response;
  }

  async function save() {
    setSaveState(AsyncOpState.Saving);
    try {
      await state.selectedProject.savePlayerSettings(PlayerSettingsInput);
      setSaveState(AsyncOpState.Success);
      setTimeout(() => {
        setSaveState(AsyncOpState.Changed);
      }, FEEDBACK_TIME);
      if (
        !currentThumbnail ||
        !currentThumbnail.endsWith(posterFrame + ".png")
      ) {
        // Wait 1 sec for the video
        await new Promise((resolve) => setTimeout(resolve, FEEDBACK_TIME));
        await generateThumbnailForProject();
      }
    } catch (error) {
      setSaveState(AsyncOpState.Error);
      console.error(error);
      setTimeout(() => setSaveState(AsyncOpState.Changed), FEEDBACK_TIME);
    }
  }

  const ColorPickerFormItem = ({
    label,
    color,
    setColor,
    disabled,
    disableAlpha = true,
  }: {
    label: string;
    color: string;
    setColor: (value: React.SetStateAction<string>) => void;
    disabled: boolean;
    disableAlpha?: boolean;
  }) => (
    <div className={`form-item ${disabled ? "disabled" : ""}`}>
      <label>{label}</label>
      <ColorPicker
        disabled={disabled}
        color={color}
        disableAlpha={disableAlpha}
        onChange={(color) => {
          disableAlpha
            ? setColor(color.hex)
            : setColor(
                color.hex +
                  Math.round((color.rgb.a || 0) * 255)
                    .toString(16)
                    .padStart(2, "0")
              );
        }}
      />
    </div>
  );

  return (
    <div className="settings-page">
      <div className="player-settings">
        <Card>
          <div className="player-load">
            <div className="subtitle"> Loading Preferences</div>
            <div className="form-item">
              <span className="card-text">Loading Image:</span>
              <div className="input-item">
                <BlingsInput
                  handleOnFocus={() => setCurrentOpenField(1)}
                  value={loadingImage}
                  uploadAssetsToProject={uploadAssetToProject}
                  onChange={(e) => {
                    setLoadingImage(e);
                  }}
                  uploadMimeType="image/*"
                />
                <Popover
                  className="tooltip-integration"
                  placement="rightTop"
                  overlayInnerStyle={{ width: "250px" }}
                  getPopupContainer={(trigger) =>
                    trigger?.parentElement || document.body
                  }
                  content={
                    <ul className="extra-info-popover">
                      <li>
                        <b>What is this:</b> Image to show during loading
                        process
                        <span
                          style={{
                            color: "var(--blings_primary)",
                            marginLeft: "-3px",
                          }}
                        ></span>
                      </li>
                      <li>
                        <b>Keep it the same ratio:</b> We recommend keeping this
                        image the same ratio as the video
                      </li>
                      <li>
                        <b>Allowed:</b> Valid image url or upload from device
                      </li>
                    </ul>
                  }
                >
                  <div onClick={() => clickField(1)}>
                    <QuestionCircleOutlined
                      style={{
                        color: "var(--blings_icon_gray)",
                        cursor: "pointer",
                      }}
                    />
                  </div>
                </Popover>
              </div>
            </div>
            <div className="form-item">
              <span className="card-text">Loading Text:</span>
              <div className="input-item">
                <BlingsInput
                  handleOnFocus={() => setCurrentOpenField(2)}
                  value={loadingText}
                  placeholder="Your video is loading"
                  // label="Text to show during loading"
                  onChange={(e) => {
                    setLoadingText(e);
                  }}
                />
                <Popover
                  className="tooltip-integration"
                  placement="rightTop"
                  overlayInnerStyle={{ width: "250px" }}
                  getPopupContainer={(trigger) =>
                    trigger?.parentElement || document.body
                  }
                  content={
                    <ul className="extra-info-popover">
                      <li>
                        <b>What is this:</b> Text to show during loading process
                        <span
                          style={{
                            color: "var(--blings_primary)",
                            marginLeft: "-3px",
                          }}
                        ></span>
                      </li>
                      <li>
                        <b>Keep it short:</b> We recommend keeping this as short
                        as possible.
                      </li>
                    </ul>
                  }
                >
                  <div onClick={() => clickField(2)}>
                    <QuestionCircleOutlined
                      style={{
                        color: "var(--blings_icon_gray)",
                        cursor: "pointer",
                      }}
                    />
                  </div>
                </Popover>
              </div>
            </div>
            <div className="form-item">
              <span className="card-text">Poster Frame:</span>
              <div className="input-item">
                <div style={{ display: "flex", alignItems: "center" }}>
                  <InputNumber
                    id="posterFrame-input"
                    min={0}
                    value={posterFrame}
                    onChange={(e) => {
                      setPosterFrame(e || 0);
                    }}
                  />
                  <Popover
                    className="tooltip-integration"
                    placement="rightTop"
                    // open={currentOpenField === 3}
                    overlayInnerStyle={{ width: "250px" }}
                    getPopupContainer={(trigger) =>
                      trigger?.parentElement || document.body
                    }
                    content={
                      <ul className="extra-info-popover">
                        <li>
                          <b>Purpose:</b> This frame is displayed during the
                          final loading phase before playback begins.
                          <span
                            style={{
                              color: "var(--blings_primary)",
                              marginLeft: "-3px",
                            }}
                          ></span>
                        </li>
                        <li>
                          <b>How to Preview:</b> Select your ideal frame by
                          adjusting it directly on this interface.
                        </li>
                        <li>
                          <b>Suggestion 1:</b> Opt for a neutral frame that
                          introduces the video content.
                        </li>
                        <li>
                          <b>Suggestion 2:</b> If there's a designated loading
                          image, select a frame that aligns closely with it for
                          a seamless transition.
                        </li>
                      </ul>
                    }
                  >
                    <div onClick={() => clickField(3)}>
                      <QuestionCircleOutlined
                        style={{
                          color: "var(--blings_icon_gray)",
                          cursor: "pointer",
                        }}
                      />
                    </div>
                  </Popover>
                </div>
              </div>
            </div>
          </div>
          <Divider className="divider" />
          <div className="subtitle"> Playback Settings</div>
          <div className="playback">
            <Checkbox
              id="startMuted-checkbox"
              checked={muted}
              onChange={(e) => {
                setMuted(e.target.checked);
              }}
            >
              Start video with mute
            </Checkbox>

            <Checkbox
              id="loop-checkbox"
              checked={autoReplay}
              onChange={(e) => {
                setAutoReplay(e.target.checked);
              }}
            >
              Loop
            </Checkbox>
            <div className="autoplay-options">
              <div className="autoplay">Autoplay Options</div>
              <Radio.Group
                defaultValue={autoplayOption}
                value={autoplayOption}
                className="radio-group"
                onChange={(e: RadioChangeEvent) => {
                  switch (e.target.value) {
                    case "on":
                      setAutoplay(true);
                      setAutoplayOption("on");
                      setAutoplay_delay(0);
                      break;
                    case "off":
                      setAutoplayOption("off");
                      setAutoplay(false);
                      break;
                    case "delay":
                      setAutoplayOption("delay");
                      setAutoplay(true);
                      break;
                  }
                }}
              >
                <Radio value={"on"}>On</Radio>
                <Radio value={"off"}>Off</Radio>
                <Radio
                  style={{ marginRight: "0", paddingRight: "0" }}
                  disabled={
                    state.selectedProject?.playerVersionToUse < "4-13-0" ||
                    state.selectedProject?.playerVersionToUse == null
                  }
                  className="last-child"
                  value={"delay"}
                >
                  Delayed Autoplay
                </Radio>

                {state.selectedProject?.playerVersionToUse >= "4-13-0" &&
                  autoplayOption === "delay" && (
                    <div className="delayed-autoplay">
                      &nbsp; <div style={{ fontSize: "14px" }}> after</div>
                      <InputNumber
                        id="delayed-autoplay-input"
                        className="delayed-autoplay-input"
                        min={0}
                        value={autoplay_delay}
                        placeholder="Time to wait before autoplay (s)"
                        onChange={(e) => {
                          setAutoplay_delay(e || 0);
                        }}
                      />
                      <div style={{ fontSize: "14px" }}>seconds</div>
                    </div>
                  )}
                {state.selectedProject?.playerVersionToUse < "4-13-0" && (
                  <Popover
                    className="tooltip-1"
                    placement="rightBottom"
                    // open={currentOpenField === 1}
                    overlayInnerStyle={{ width: "250px", position: "absolute" }}
                    getPopupContainer={(trigger) =>
                      trigger?.parentElement || document.body
                    }
                    content={
                      <ul className="extra-info-popover">
                        <li>
                          <b>Required:</b> Projects with version {">"} 4.13
                          <span
                            style={{
                              color: "var(--blings_primary)",
                              marginLeft: "-3px",
                            }}
                          ></span>
                        </li>
                      </ul>
                    }
                  >
                    <div onClick={() => clickField(1)}>
                      <QuestionCircleOutlined
                        style={{
                          color: "var(--blings_icon_gray)",
                          cursor: "pointer",
                        }}
                      />
                    </div>
                  </Popover>
                )}
              </Radio.Group>
            </div>
          </div>
          <Divider className="divider" />
          <div className="subtitle"> Timeline Settings</div>
          <div className="timeline-settings">
            <div className="form-item">
              <label>Timeline</label>
              <Switch
                size="small"
                checked={showTimeline}
                onChange={handleTimeLineChange}
              />
            </div>
            <div className="form-item">
              <label
                className={[!showTimeline ? "text-disabled" : ""].join(" ")}
              >
                Story Mode
              </label>
              <Switch
                disabled={!showTimeline}
                size="small"
                checked={storieMode}
                onChange={handleStoryModeChange}
              />
              <Popover
                className="tooltip-1"
                placement="rightBottom"
                // open={currentOpenField === 1}
                overlayInnerStyle={{ width: "500px", position: "absolute" }}
                getPopupContainer={(trigger) =>
                  trigger?.parentElement || document.body
                }
                content={
                  <div>
                    <div style={{ marginBottom: "10px" }}>
                      <span style={{ marginRight: "8px", fontWeight: "bold" }}>
                        Timeline:
                      </span>
                      <img
                        src={timeline}
                        alt="timeline example"
                        style={{ width: "100%" }}
                      />
                    </div>
                    <div>
                      <span style={{ marginRight: "8px", fontWeight: "bold" }}>
                        Story Mode:
                      </span>
                      <img
                        src={storiesMode}
                        alt="story mode example"
                        style={{ width: "100%" }}
                      />
                    </div>
                  </div>
                }
              >
                <div>
                  <QuestionCircleOutlined
                    style={{
                      color: "var(--blings_icon_gray)",
                      cursor: "pointer",
                    }}
                  />
                </div>
              </Popover>
            </div>
          </div>
          <Divider className="divider" />
          <div className="subtitle">Color Customization</div>
          <div className="color-customization">
            <div className="row">
              <div className="sub-header">
                <div>General</div>
                <Popover
                  className="tooltip"
                  placement="rightBottom"
                  overlayInnerStyle={{ width: "50rem" }}
                  getPopupContainer={(trigger) =>
                    trigger?.parentElement || document.body
                  }
                  content={
                    <div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginBottom: "10px",
                        }}
                      >
                        <span style={{ width: "7rem", fontWeight: "bold" }}>
                          Background:
                        </span>
                        <img
                          src={player_settings_bg}
                          alt="Background"
                          style={{ height: "7rem" }}
                        />
                      </div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginBottom: "10px",
                        }}
                      >
                        <span style={{ width: "7rem", fontWeight: "bold" }}>
                          Loader:
                        </span>
                        <img
                          src={player_settings_loader}
                          alt="Loader"
                          style={{ height: "7rem" }}
                        />
                      </div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginTop: "1.5rem",
                        }}
                      >
                        <span style={{ width: "7rem", fontWeight: "bold" }}>
                          Buttons:
                        </span>
                        <div>
                          <img
                            src={timeline_color_control}
                            alt="Timeline Control"
                            style={{ width: "37rem", marginBottom: "14px" }}
                          />
                          <img
                            src={storymode_color_control}
                            alt="Storymode Control"
                            style={{ width: "37rem" }}
                          />
                        </div>
                      </div>
                    </div>
                  }
                >
                  <div onClick={() => clickField(1)}>
                    <QuestionCircleOutlined
                      style={{
                        color: "var(--blings_icon_gray)",
                        cursor: "pointer",
                      }}
                    />
                  </div>
                </Popover>
              </div>

              {ColorPickerFormItem({
                label: "Background:",
                color: backgroundColor,
                setColor: setBackgroundColor,
                disabled: false,
              })}
              {ColorPickerFormItem({
                label: "Loader:",
                color: loaderColor,
                setColor: setLoaderColor,
                disabled: false,
              })}
              {ColorPickerFormItem({
                label: "Buttons:",
                color: buttonsColor,
                setColor: setButtonsColor,
                disabled: false,
              })}
            </div>

            <div className="row">
              <div className="sub-header">
                <div>Timeline</div>
                <Popover
                  className="tooltip"
                  placement="rightBottom"
                  overlayInnerStyle={{ width: "50rem", position: "absolute" }}
                  getPopupContainer={(trigger) =>
                    trigger?.parentElement || document.body
                  }
                  content={
                    <div>
                      <div
                        style={{
                          display: "table",
                          marginBottom: "10px",
                          width: "100%",
                        }}
                      >
                        <div style={{ display: "table-row" }}>
                          <div
                            style={{
                              display: "table-cell",
                              fontWeight: "bold",
                              paddingRight: "1rem",
                              width: "5rem",
                            }}
                          >
                            Progress:
                          </div>
                          <div style={{ display: "table-cell" }}>
                            <img
                              src={storieMode ? story_progress : progress}
                              alt="Progress"
                              style={{ width: "100%", maxWidth: "37rem" }}
                            />
                          </div>
                        </div>
                      </div>
                      <div
                        style={{
                          display: "table",
                          marginBottom: "10px",
                          width: "100%",
                        }}
                      >
                        <div style={{ display: "table-row" }}>
                          <div
                            style={{
                              display: "table-cell",
                              fontWeight: "bold",
                              paddingRight: "1rem",
                              width: "5rem",
                            }}
                          >
                            Rail:
                          </div>
                          <div style={{ display: "table-cell" }}>
                            <img
                              src={storieMode ? story_rail : rail}
                              alt="Rail"
                              style={{ width: "100%", maxWidth: "37rem" }}
                            />
                          </div>
                        </div>
                      </div>
                      {!storieMode ? (
                        <div style={{ display: "table", width: "100%" }}>
                          <div style={{ display: "table-row" }}>
                            <div
                              style={{
                                display: "table-cell",
                                fontWeight: "bold",
                                paddingRight: "1rem",
                                verticalAlign: "bottom",
                                width: "5rem",
                              }}
                            >
                              Thumb:
                            </div>
                            <div style={{ display: "table-cell" }}>
                              <img
                                src={thumb}
                                alt="Thumb"
                                style={{ width: "100%", maxWidth: "37rem" }}
                              />
                            </div>
                          </div>
                        </div>
                      ) : null}
                    </div>
                  }
                >
                  <div onClick={() => clickField(1)}>
                    <QuestionCircleOutlined
                      style={{
                        color: "var(--blings_icon_gray)",
                        cursor: "pointer",
                      }}
                    />
                  </div>
                </Popover>
              </div>

              <>
                {ColorPickerFormItem({
                  label: "Progress:",
                  color: progressColor,
                  setColor: setProgressColor,
                  disabled: !showTimeline,
                  disableAlpha: false,
                })}
                {ColorPickerFormItem({
                  label: "Rail:",
                  color: railColor,
                  setColor: setRailColor,
                  disabled: !showTimeline,
                  disableAlpha: !storieMode,
                })}

                {ColorPickerFormItem({
                  label: "Thumb:",
                  color: thumbColor,
                  setColor: setThumbColor,
                  disabled: !showTimeline || storieMode,
                })}
              </>
            </div>
          </div>

          <Divider className="divider" />
          <div className="save-btn-wrapper">
            <BlingsBtn
              className="save-btn"
              opState={saveState}
              htmlType={"submit"}
              btnTexts={FEEDBACK_OPTIONS}
              onClick={() => {
                save();
              }}
            />
          </div>
        </Card>
      </div>
      <div className="tab-player-container">
        <PlayerComponent
          commandBarOptions={{
            showGlobalMute: true,
            showFrameIndicator: true,
          }}
          playerOptions={{
            projectID: project.id,
            data: data,
            settings: playerSettingsForPlayer,
            useScenesFromContext: true,
          }}
        />
      </div>
    </div>
  );
};
